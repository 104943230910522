
import React, { useEffect, useState } from "react";
import { cardIcon, closeIcon, qrIcon } from "../../../Base/SVG";
import { motion } from "framer-motion";
import MaskedInput from "react-text-mask";

import { getCards } from "../../../service/payment";
import {
  AMERICANEXPRESS,
  OTHERCARDS,
  EXPIRYDATE,
  CVC,
  CARDARR,
  CARDICON,
} from "../../../Base/constants";
import {
  minLength,
  stripeCardExpirValidation,
  stripeCardNumberValidation,
  textWithSpacesOnly,
} from "../../../Base/validation";
import { addCard, deleteCard } from "../../../service/payment";
import { toast } from "react-toastify";
export default function Edit({
  form,
  create,
  setCreate,
  updateForm,
  editCard,
  setEditCard,
}) {

  const [pos, setPos] = useState({
    id: editCard.id,
    name: editCard.name,
    date: editCard.date,
    cardNumber: editCard.cardNumber,
    cvv: editCard.cvv,
    save: editCard.save,
    cardImage: editCard.cardImage,
    cardType: editCard.cardType,
  });

  const [error, setError] = useState({});
  const [cardType, setCardType] = useState(editCard.cardType);
  const updatePos = (data) => {
    setPos((pos) => ({ ...pos, ...data }));
  };
  const onChangeInput = (input) => (e) => {
    setPos((pos) => ({ ...pos, [input]: e.target.value }));
  };
  useEffect(() => {
    setPos({
      id: editCard.id,
      name: editCard.name,
      date: editCard.date,
      cardNumber: editCard.cardNumber,
      cvv: editCard.cvv,
      save: editCard.save,
      cardImage: editCard.cardImage,
      cardType: editCard.cardType,
    });
    setCardType(editCard.cardType);
  }, [editCard]);

  const deleteCardFromEntry = async () => {

    try {

      let payload = { cardId: pos.id }
      let { data } = await deleteCard(payload);
      if (data?.success) {
        console.log('after delete', data)
        toast.success("Card deleted successfully")
        updateForm({ cards: form.cards.filter((item) => item.id !== pos.id) });
        setEditCard(null);
        setCreate(null);
      }
    } catch (error) {
      console.log('error in saving card', error)
    }


  };
  const onClose = (e) => {
    if (e.target === e.currentTarget) setCreate(null);
  };
  function findDebitCardType(cardNumber) {
    const regexPattern = {
      MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
      VISA: /^4[0-9]{2,}$/,
      AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
      DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
      DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
      JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
    };
    for (const card in regexPattern) {
      if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card]))
        return card;
    }
    return "";
  }

  const handleValidations = (type, value) => {
    let errorText;
    switch (type) {
      case "card":
        let cardInfo = findDebitCardType(value);
        setCardType(findDebitCardType(value));
        updatePos({ cardImage: CARDICON[cardInfo], cardType: cardInfo });
        errorText = stripeCardNumberValidation(value);
        setError({ ...error, cardNumberError: errorText });
        break;
      case "cardHodler":
        errorText = value === "" ? "Required" : textWithSpacesOnly(value);
        setError({ ...error, nameError: errorText });
        break;
      case "expiry":
        errorText =
          value === "" ? "Required" : stripeCardExpirValidation(value);
        setError({ ...error, dateError: errorText });
        break;
      case "securityCode":
        errorText = value === "" ? "Required" : minLength(3)(value);
        setError({ ...error, cvvError: errorText });
        break;
      default:
        break;
    }
  };

  const handleBlur = (e) => {
    handleValidations(e.target.name, e.target.value);
  };
  const checkErrorBeforeSave = () => {
    let errorValue = {};
    let isError = false;
    Object.keys(pos).forEach(async (val) => {
      if (
        pos?.[val] === "" &&
        val !== "id" &&
        val !== "cardImage" &&
        val !== "cardType"
      ) {
        errorValue = { ...errorValue, [`${val + "Error"}`]: "Required" };
        isError = true;
      }
    });
    setError(errorValue);
    return isError;
  };

  const handleSave = async () => {
    let errorCheck = checkErrorBeforeSave();
    if (!errorCheck) {
      let arr = [...form.cards];
      const itemIndex = arr.indexOf(pos);
      arr[itemIndex] = {
        ...pos,
        id: form.cards.length + 1,
      };

      try {



        let payload = {
          number: pos?.cardNumber,
          exp_month: pos?.date?.toString().split('/')[0],
          exp_year: pos?.date?.toString().split('/')[1],
          cvc: pos?.cvv
        }

        let { data } = await addCard(payload);
        if (data?.success) {
          console.log('after save', data)
          updateForm({ cards: arr });
          setEditCard(null);
          setCreate(null);
        }
      } catch (error) {
        console.log('error in saving card', error)
      }


    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
      exit={{ opacity: 0 }}
      className={"walletSide__create " + (create === "edit" ? "active" : "")}
      onClick={onClose}
    >
      <div className="walletSide__create-inner">
        <h4>
          Your Credit Card{" "}
          <button onClick={onClose} type="button">
            {closeIcon}
          </button>
        </h4>
        <div className="card big">
          <div className="card__bg">
            <img
              src={process.env.PUBLIC_URL + "/images/place.png"}
              alt="place"
            />
          </div>
          <div className="card__inner">
            <div className="card__head">
              <div className="card__head-icon">{cardIcon}</div>
              <div className="card__head-type">
                <img src={editCard.cardImage} alt="card" />
                <span>{cardType}</span>
              </div>
            </div>
            <div className="card__body">
              <h6>{pos.name === "" ? "Card Holder's Name" : pos.name}</h6>
              <p className="sm">
                **** **** ****{" "}
                {pos.cardNumber === "" ? "0000" : pos.cardNumber.slice(-4)}
              </p>
              <p className="xsm">{pos.date === "" ? "00/00" : pos.date}</p>
            </div>
          </div>
        </div>
        <button type="button" className="qr">
          {qrIcon} Scan your card
        </button>
        <div className="walletInput__outer">
          <label htmlFor="">Cardholder name</label>

          <div className="walletInput">
            {/* <input
              type="text"
              name="cardHodler"
              required
              placeholder="CardHolder's Name"
              value={pos.name}
              onChange={onChangeInput("name")}
              onBlur={handleBlur}
            /> */}
            {pos.name}
            {/* {error && error.nameError && error.nameError.length > 1 && (
              <span>{error.nameError}</span>
            )} */}
          </div>
        </div>
        <div className="walletInput__outer">
          <label htmlFor="">Card number</label>
          <div className="walletInput">
            {/* <MaskedInput
              mask={
                ["37", "34"].includes(
                  pos.cardNumber &&
                  pos.cardNumber.split("").splice(0, 2).join("")
                )
                  ? AMERICANEXPRESS
                  : OTHERCARDS
              }
              guide={false}
              placeholderChar={"\u2000"}
              placeholder="Card Number"
              name="card"
              required
              value={pos.cardNumber}
              onChange={onChangeInput("cardNumber")}
              onBlur={handleBlur}
            /> */}
            {pos.cardNumber}
            {/* {error &&
              error.cardNumberError &&
              error.cardNumberError.length > 1 && (
                <span>{error.cardNumberError}</span>
              )} */}
          </div>
        </div>
        <div className="walletInput__row">
          <div className="walletInput__outer">
            <label htmlFor="">Valid Through</label>
            <div className="walletInput">
              {/* <MaskedInput
                mask={EXPIRYDATE}
                guide={false}
                name="expiry"
                required
                placeholderChar={"\u2000"}
                placeholder="Expiry Date (MM/YY)"
                value={pos.date}
                onChange={onChangeInput("date")}
                onBlur={handleBlur}
              /> */}
              {pos.date}
              {/* {error && error.dateError && error.dateError.length > 1 && (
                <span>{error.dateError}</span>
              )} */}
            </div>
          </div>
          <div className="walletInput__outer">
            <label htmlFor="">CVV</label>
            <div className="walletInput">
              {/* <MaskedInput
                mask={CVC}
                guide={false}
                name="securityCode"
                required
                placeholderChar={"\u2000"}
                placeholder="CVV"
                value={pos.cvv}
                onChange={onChangeInput("cvv")}
                onBlur={handleBlur}
              /> */}
              {pos.cvv}
              {/* {error && error.cvvError && error.cvvError.length > 1 && (
                <span>{error.cvvError}</span>
              )} */}
            </div>
          </div>
        </div>
        {/* <div className="check">
          <input
            type="checkbox"
            checked={pos.save}
            onChange={(e) => {
              updatePos({ save: e.target.checked });
            }}
          />
          <label htmlFor="">Save Your Card Details</label>
        </div> */}
        <div className="button__row">
          <button
            type="button"
            onClick={deleteCardFromEntry}
            className="button bordered"
          >
            Delete
          </button>
          {/* <button type="button" onClick={handleSave} className="button green">
            Update
          </button> */}
        </div>
      </div>
    </motion.div>
  );
}
