import React, { useState, useRef } from "react";
import LoadingRing from "./LoadingRing";
import { tickIcon } from "../../../../../Base/SVG";
import { uploadFile } from "../../../../../service/user";
import api from "../../../../../config/api";
import axios from "axios";
import { toast } from "react-toastify";
const fetchingStates = {
  idle: "STATE_IDLE",
  error: "STATE_ERROR",
  fetching: "STATE_FETCHING",
  success: "STATE_SUCCESS",
};

const ContractDoc = ({ imageData, updatePos, error }) => {
  const [status, setStatus] = useState(fetchingStates.idle);
  const onLoad = async (e) => {
    setStatus(fetchingStates.fetching);
    try {
      if (e.target.files[0]) {
        updatePos({
          contract_file: e.target.files[0],
        });
        setStatus(fetchingStates.idle);

          let formData = new FormData();
          formData.append("file", e.target.files[0]);

          let { data } = await axios.post(api.user.uploadFile, formData, {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (data?.success) {
            updatePos({
              contract_file: data?.data,
            });
          } else {
            toast.error("File is too large");
          }
        
      }
      e.target.value = null;
    } catch (e) {
      console.log("error in uploading file", error);
    }
  };
  return (
    <div className="fileUp__outer">
      <label htmlFor="">Tenancy Contract (.pdf .doc )</label>
      <div className="fileUp">
        {imageData && (
          <div className="fileUp__text">
            {imageData.name} {tickIcon}
          </div>
        )}
        <div className="fileUp__btn">
          <input
            type="file"
            onChange={onLoad}
            accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          {status === fetchingStates.fetching ? (
            <LoadingRing size={"12px"} color={"#7de19a"} />
          ) : (
            <span>Upload</span>
          )}
        </div>
      </div>
      {error && imageData === "" && (
        <span className="errorM">File upload Required</span>
      )}
    </div>
  );
};

export default ContractDoc;
