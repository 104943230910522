import React, { useEffect, useState } from "react";
import { notifIcon } from "../../../../Base/SVG";
import { getTransactionFee } from "../../../../service/payment";

export default function Details({ pos }) {
  const [details, setDetails] = useState({
    fees: 0,
    deliveryCost: 0,
 ach_wire_fee: 0,
    total: 0,
  });
  const [transactionType, setTransactionType] = useState("");
  const initialBreakdown = {
    payque_fee: 0,
    stripe_fee: 0,
    additional_stripe_fee: 0,
    cheque_delivery_cost: 0,
    wire_flat_fee: 0, // Or omit if you don't want to initialize
  };

  const [breakDown, setBreakDown] = useState(initialBreakdown);

  const fetchData = async (pos) => {
    try {
      let transactionTypeValue = pos.payment_type.value;
      if (transactionTypeValue === "Cheque Delivery") {
        transactionTypeValue = "cheque";
        setTransactionType("cheque");
      } else if (transactionTypeValue === "e-interac") {
        transactionTypeValue = "e-interac";
        setTransactionType("e-interac");
      } else if (transactionTypeValue === "ACH Bank Transfer (Wire)") {
        transactionTypeValue = "wire";
        setTransactionType("wire");
      }
      const response = await getTransactionFee({
        transactionType: transactionTypeValue,
        amount: pos.amount,
      });
      if (response?.data?.success) {
        // const fee = pos.fees;
        const fee = response?.data.data.fee || 0;
        // const deliveryCost = response.data.data.delivery_cost;
        let newTotal = response?.data.data.total_payment || 0;
        // if (transactionTypeValue === "cheque") {
        //   newTotal = Number(pos.amount) + fee;
        // } else if (transactionTypeValue === "wire") {
        //   newTotal = Number(pos.amount) + fee;
        // } else if (transactionTypeValue === "e-interac") {
        //   newTotal = Number(pos.amount) + fee;
        // }
        setDetails((prevDetails) => ({
          ...prevDetails,
          fees: fee,
          deliveryCost: response.data.data.delivery_cost||0,
          ach_wire_fee: response.data.data.ach_wire_fee||0,
          total: newTotal,
        }));
        const {
          payque_fee = 0,
          stripe_fee = 0,
          additional_stripe_fee = 0,
          cheque_delivery_cost = 0,
          wire_flat_fee = 0,
        } = response.data.data.breakdown;

        setBreakDown((prevBreakdown) => ({
          ...prevBreakdown,
          payque_fee,
          stripe_fee,
          additional_stripe_fee,
          cheque_delivery_cost,
          wire_flat_fee,
        }));
        // setBreakDown((prevBreakDown) => ({
        //   ...prevBreakDown,
        //   payque_fee: response.data.data.breakdown.payque_fee,
        //   stripe_fee: response.data.data.breakdown.stripe_fee,
        //   additional_stripe_fee: response.data.data.breakdown.additional_stripe_fee,
        //   delivery_cost: response.data.data.breakdown.delivery_cost,
        // }));
      } else {
        console.error("Error fetching transaction fee:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching transaction fee:", error);
    }
  };

  useEffect(() => {
    fetchData(pos);
  }, [pos]);

  return (
    <div className="summary__details">
      <div className="summary__details-head">
        <h4>Order Summary</h4>
        <p>Review your payment</p>
      </div>

      <div className="summaryDetail">
        {pos.payment_type.value === "e-interac" && <h6>e-Interac Order:</h6>}
        {pos.payment_type.value === "ACH Bank Transfer (Wire)" && (
          <h6>ACH Wire Order:</h6>
        )}
        {pos.payment_type.value === "Cheque Delivery" && <h6>Cheque Order:</h6>}
        {pos.amount ? <p>${pos.amount} CAD</p> : <p>$ 0 CAD</p>}
      </div>
      <div className="summaryDetail">
        <h6>
          Fees:{" "}
          <div className="tool">
            <div className="tool__icon">{notifIcon}</div>
            <div className="tool__mess">
              <p>
                <strong>Payque Fee:</strong> ${breakDown.payque_fee?.toFixed(2)}{" "}
                CAD
                <br />
                <strong>Stripe Fee:</strong> ${breakDown.stripe_fee?.toFixed(2)}{" "}
                CAD
                <br />
                <strong>Additional Stripe Fee:</strong> $
                {breakDown.additional_stripe_fee?.toFixed(2)} CAD
                {breakDown.cheque_delivery_cost !== 0 ? (
                  <>
                    <br />
                    <strong>Cheque Delivery Cost:</strong> $
                    {breakDown.cheque_delivery_cost?.toFixed(2)} CAD
                  </>
                ) : null}
                {breakDown.wire_flat_fee !== 0 ? (
                  <>
                    <br />
                    <strong>Wire Flat Fee:</strong> $
                    {breakDown.wire_flat_fee?.toFixed(2)} CAD
                  </>
                ) : null}
              </p>
            </div>
          </div>
        </h6>
        <p>${details?.fees?.toFixed(2)} CAD</p>
      </div>
      {/* {pos.payment_type.value === "Cheque Delivery" && (
        <div className="summaryDetail">
          <h6>Delivery Cost:</h6>
          <p>${details.deliveryCost?.toFixed(2)} CAD</p>
        </div>
      )}
      {pos.payment_type.value === "ACH Bank Transfer (Wire)" && (
        <div className="summaryDetail">
          <h6>ACH Wire Fees:</h6>
          <p>${details.deliveryCost?.toFixed(2)} CAD</p>
        </div>
      )} */}
      {transactionType === "cheque" && (
        <div className="summaryDetail">
          <h6>
          Delivery Cost
          </h6>
          <p>
          $ {details?.deliveryCost?.toFixed(2) || 0} CAD
          </p>
        </div>
      )}
      {transactionType === "wire" && (
        <div className="summaryDetail">
          <h6>
          ACH wire fee
          </h6>
          <p>
          $ {details?.ach_wire_fee?.toFixed(2) || 0} CAD
          </p>
        </div>
      )}
     

      <div className="summaryDetail">
        <h6>
          <strong>Total Payment</strong>
        </h6>
        <p>
          <strong>${details.total?.toFixed(2) ||0} CAD</strong>
        </p>
      </div>
    </div>
  );
}
