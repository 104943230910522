import React from "react";
import Header from "../../../Base/Header";
import Uploads from "./components/Uploads/Uploads";
import Info from "./components/Info/Info";

export default function Main({
  pos,
  updatePos,
  onChangeInput,
  payeeHandler,
  isEdit,
  error,
  setError,
  form
}) {
  return (
    <div className="contractMain">
      <Header title="Tenancy Contract" text="Manage your contract" form={form}/>
      <Uploads
        pos={pos}
        updatePos={updatePos}
        error={error}
        setError={setError}
      />
      <Info
        error={error}
        payeeHandler={payeeHandler}
        pos={pos}
        isEdit={isEdit}
        updatePos={updatePos}
        onChangeInput={onChangeInput}
      />
    </div>
  );
}
