import React from "react";

import { usePagination, DOTS } from "../../Hooks/usePagination";
import { chevronLeft, chevronRight } from "../../Base/SVG";

export const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className="pagination">
      {/* Left navigation arrow */}
      <li
        className={"pagination__btn " + (currentPage === 1 ? "disabled" : "")}
        onClick={onPrevious}
      >
        {chevronLeft}
      </li>
      <div className="pagination__nums">
        {paginationRange.map((pageNumber, index) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === DOTS) {
            return (
              <li key={index} className="pagination__num">
                &#8230;
              </li>
            );
          }

          // Render our Page Pills
          return (
            <li
              key={index}
              className={
                "pagination__num " +
                (pageNumber === currentPage ? "selected" : "")
              }
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
      </div>
      {/*  Right Navigation arrow */}
      <li
        className={
          "pagination__btn " + (currentPage === lastPage ? "disabled" : "")
        }
        onClick={onNext}
      >
        {chevronRight}
      </li>
    </div>
  );
};
