import React from "react";
import Quick from "./Quick";
import Cards from "./Cards";
import Activity from "./Activity";

export default function Side({ form }) {
  return (
    <div className="overviewSide">
      <Cards form={form} />
      <Activity />
      <Quick />
    </div>
  );
}
