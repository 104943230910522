import React from "react";

function LoadingRing(props) {
  const ringStyle = React.useMemo(() => {
    let newStyle = {};
    if (props.color)
      newStyle.borderColor = `${props.color} transparent transparent transparent `;
    return newStyle;
  }, [props]);
  const wrapperStyle = React.useMemo(() => {
    let newStyle = {};
    if (props.opacity) newStyle.opacity = props.opacity ?? 1;
    if (props.size) newStyle.fontSize = "" + props.size;
    if (props.color) newStyle.borderColor = `${props.color}25`;
    return newStyle;
  }, [props]);
console.log(props)
  return (
    <div className="lds-ring"  style={wrapperStyle}>
      <div style={ringStyle}></div>
      <div style={ringStyle}></div>
    </div>
  );
}

export default LoadingRing;
