import React from 'react'
import PhotoId from '../../Contract/components/Main/components/Uploads/PhotoId'

function Identity() {
  return (
    <div className="profileMain__detail">
    <div className="profileMain__detail-info">
  <PhotoId/>
    </div>
 
  </div>
  )
}

export default Identity