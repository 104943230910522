import React, { useEffect, useState } from "react";

export default function Type3({ pos, updatePos, error,isEdit }) {
  const [typeInfo, setTypeInfo] = useState(pos.wire);

  const onChangeInput = (input) => (e) => {
    setTypeInfo((typeInfo) => ({ ...typeInfo, [input]: e.target.value }));
  };

  useEffect(() => {
    updatePos({ wire: typeInfo });
  }, [typeInfo]);
  useEffect(() => {
    setTypeInfo(pos.wire);
  }, [isEdit]);
  return (
    <>
      <div className="formm__double">
        <div className="input__outer">
          <label htmlFor="">Last Name or Company Name</label>
          <div
            className={
              "input " + (error && pos.wire.companyName === "" ? "error" : "")
            }
          >
            <input
              type="text"
              value={typeInfo.companyName}
              onChange={onChangeInput("companyName")}
            />
            {error && pos.wire.companyName === "" && (
              <span className="errorM">This field required</span>
            )}
          </div>
        </div>
        <div className="input__outer">
          <label htmlFor="">First Name (Optional if Company)</label>
          <div
            className={
              "input " + (error && pos.wire.firstName === "" ? "error" : "")
            }
          >
            <input
              type="text"
              value={typeInfo.firstName}
              onChange={onChangeInput("firstName")}
            />
            {error && pos.wire.firstName === "" && (
              <span className="errorM">This field required</span>
            )}
          </div>
        </div>
      </div>
      <div className="input__outer">
        <label htmlFor="">Recipient’s RBC Bank Account Details</label>
      </div>
      <div className="formm__double">
        <div className="input__outer">
          <label htmlFor="">RBC Branch (Transit Number (5-digits)</label>
          <div
            className={
              "input " + (error && pos.wire.branchNumber === "" ? "error" : "")
            }
          >
            <input
              type="text"
              value={typeInfo.branchNumber}
              onChange={onChangeInput("branchNumber")}
            />
            {error && pos.wire.branchNumber === "" && (
              <span className="errorM">This field required</span>
            )}
          </div>
        </div>
        <div className="input__outer">
          <label htmlFor="">RBC Account Number (5-7 digits)</label>
          <div
            className={
              "input " + (error && pos.wire.accountNumber === "" ? "error" : "")
            }
          >
            <input
              type="text"
              value={typeInfo.accountNumber}
              onChange={onChangeInput("accountNumber")}
            />
            {error && pos.wire.accountNumber === "" && (
              <span className="errorM">This field required</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
