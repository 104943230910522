import React, { useEffect, useState } from "react";

export default function Type3({ pos, updatePos, error,form }) {
  const [typeInfo, setTypeInfo] = useState(pos.ach_bank_transfer);

  const onChangeInput = (input) => (e) => {
    setTypeInfo((typeInfo) => ({ ...typeInfo, [input]: e.target.value }));
  };

  useEffect(() => {
    updatePos({ ach_bank_transfer: typeInfo });
  }, [typeInfo]);
  
  useEffect(() => {
    const filteredCompanies = form.companies.filter(
      (company) => company.paying_for === pos.pay
    );
    if (filteredCompanies.length > 0) {
      const firstCompany = filteredCompanies[0];
      setTypeInfo((typeInfo) => ({
        ...typeInfo,
        companyName: firstCompany.wire.companyName,
        firstName: firstCompany.wire.firstName
      }));
    }
  }, [form.companies, pos.pay]);

  return (
    <>
      <div className="formm__double">
        <div className="input__outer">
          <label htmlFor="">Last Name or Company Name</label>
          <div
            className={
              "input " + (error && pos.ach_bank_transfer.companyName === "" ? "error" : "")
            }
          >
            <input
              type="text"
              value={typeInfo.companyName}
              onChange={onChangeInput("companyName")}
            />
            {error && pos.ach_bank_transfer.companyName === "" && (
              <span className="errorM">This field required</span>
            )}
          </div>
        </div>
        <div className="input__outer">
          <label htmlFor="">First Name (Optional if Company)</label>
          <div
            className={
              "input " + (error && pos.ach_bank_transfer.firstName === "" ? "error" : "")
            }
          >
            <input
              type="text"
              value={typeInfo.firstName}
              onChange={onChangeInput("firstName")}
            />
            {error && pos.ach_bank_transfer.firstName === "" && (
              <span className="errorM">This field required</span>
            )}
          </div>
        </div>
      </div>
      <div className="input__outer">
        <label htmlFor="">Recipient’s RBC Bank Account Details</label>
      </div>
      <div className="formm__double">
        <div className="input__outer">
          <label htmlFor="">RBC Branch (Transit Number (5-digits)</label>
          <div
            className={
              "input " + (error && pos.ach_bank_transfer.branchNumber === "" ? "error" : "")
            }
          >
            <input
              type="text"
              value={typeInfo.branchNumber}
              onChange={onChangeInput("branchNumber")}
            />
            {error && pos.ach_bank_transfer.branchNumber === "" && (
              <span className="errorM">This field required</span>
            )}
          </div>
        </div>
        <div className="input__outer">
          <label htmlFor="">RBC Account Number (5-7 digits)</label>
          <div
            className={
              "input " + (error && pos.ach_bank_transfer.accountNumber === "" ? "error" : "")
            }
          >
            <input
              type="text"
              value={typeInfo.accountNumber}
              onChange={onChangeInput("accountNumber")}
            />
            {error && pos.ach_bank_transfer.accountNumber === "" && (
              <span className="errorM">This field required</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
