import axios from "axios";
import api from "../config/api";

export const sendOpt = async ({ id, data }) => {
  //email
  let request = await axios({
    method: "post",
    url: `${api.auth.sendOtp}`.replace(":id", id),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      // Authorization: `Bearer ${api.token}`,
    },
    data,
  }).catch((err) => new Error(err));
  return request;
};

export const verifyOtp = async ({ id, data }) => {
  //email,otp
  console.log(data);
  let request = await axios({
    method: "post",
    url: `${api.auth.verifyOtp}`.replace(":id", id),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data,
  }).catch((err) => new Error(err));
  return request;
};

export const signUp = async (data) => {
  let request = await axios({
    method: "post",
    url: `${api.auth.signUp}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data,
  }).catch((err) => new Error(err));
  return request;
};

export const login = async (data) => {
  let request = await axios({
    method: "post",
    url: `${api.auth.login}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data,
  }).catch((err) => new Error(err));
  return request;
};

export const otpLogin = async (data) => {
  let request = await axios({
    method: "post",
    url: `${api.auth.otpLogin}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data,
  }).catch((err) => new Error(err));
  return request;
};

export const googleLogin = async () => {
  try {
    let request = await axios({
      method: "get",
      url: `${api.auth.googleLogin}`,
    });
    return request;
  } catch (err) {
    console.log(err);
  }
};
export const verifyGoogleLogin = async (token) => {
  try {
    const googleLoginUrl = api.auth.verifyGoogle + `?google_sign=${token}`;
    const response = await axios.get(googleLoginUrl);
    // const response = {
    //     status:202,
    //     data:{
    //         data:{
    //             AccessToken:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3NfdXVpZCI6IjBhYjQzNTMzLTAwYzQtNGI0Zi1iOTI2LWM0MWRhMzA0NTA0NCIsImF1dGhvcml6ZWQiOnRydWUsImV4cCI6MTcwMTM4NzA0NCwibWV0YV9kYXRhIjoic2h1YmhhbUBvcmdvc3lzLmNvbSJ9.GuKLEvkOfFqGIjA1MmtYdaaohkd4gYZwivE5QB7QUeo"
    //         }
    //     }
    // }
    const { status } = response;
    if (status === 202) {
      const { data } = response;
      const { data: finalData } = data;
      return finalData || {};
    } else if (status === 401) {
      const { data } = response;
      const { message } = data;
      console.log(message);
      return false;
    } else {
      return false;
    }
  } catch (error) {
    const { response } = error || {};
    if (response && response.status === 401) {
      console.log("Invalid email or password");
      return false;
    } else {
      return false;
    }
  }
};

export const forgot = async (data) => {
  let request = await axios({
    method: "post",
    url: `${api.auth.forgot}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data,
  }).catch((err) => new Error(err));
  return request;
};
export const resetPassword = async (email, token, new_password) => {
  let request = await axios({
    method: "get",
    url: `${api.auth.reset}`
      .replace("{{email}}", email)
      .replace("{{token}}", token)
      .replace("{{new_password}}", new_password),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).catch((err) => new Error(err));
  return request;
};
// export const getProfile = async () => {
//     let request = await axios({
//         method: "get",
//         url: `${api.user.getprofile}`,
//         headers: {
//             Authorization: `Bearer ${api.token}`,
//         },
//     }).catch(err => new Error(err));
//     return request;
// };

// export const createPayee = async (data) => {
//     let request = await axios({
//         method: "post",
//         url: `${api.user.createPayee}`,
//         headers: {
//             Authorization: `Bearer ${api.token}`,
//         },
//         data,
//     }).catch(err => new Error(err));
//     return request;
// };

// export const getPayee = async ({ pageNumber = 1, pageSize = 10 }) => {
//     let request = await axios({
//         method: "get",
//         url: `${api.user.getPayee}`.replace('{{page_number}}', pageNumber).replace('{{page_size}}', pageSize),
//         headers: {
//             Authorization: `Bearer ${api.token}`,
//         },
//     }).catch(err => new Error(err));
//     return request;
// };

// export const deletePayee = async ({ payee_id }) => {
//     let request = await axios({
//         method: "delete",
//         url: `${api.user.deletePayee}`.replace('{{payee_id}}', payee_id),
//         headers: {
//             Authorization: `Bearer ${api.token}`,
//         },
//     }).catch(err => new Error(err));
//     return request;
// };

// export const uploadFile = async (data) => {
//     let request = await axios({
//         method: "post",
//         url: `${api.user.uploadFile}`,
//         headers: {
//             Authorization: `Bearer ${api.token}`,
//         },
//         data,
//     }).catch(err => new Error(err));
//     return request;
// };

console.log("");

// export const addCard = async (data) => {
//     let request = await axios({
//         method: "post",
//         url: `${api.payment.card.addCard}`,
//         headers: {
//             Authorization: `Bearer ${api.token}`,
//         },
//         data,
//     }).catch(err => new Error(err));
//     return request;
// };

// export const getCards = async () => {
//     let request = await axios({
//         method: "get",
//         url: `${api.payment.card.getCard}`,
//         headers: {
//             Authorization: `Bearer ${api.token}`,
//         },
//     }).catch(err => new Error(err));
//     return request;
// };

// export const deleteCard = async ({ cardId }) => {
//     let request = await axios({
//         method: "delete",
//         url: `${api.payment.card.deleteCard}`.replace('{{cardId}}', cardId),
//         headers: {
//             Authorization: `Bearer ${api.token}`,
//         },
//     }).catch(err => new Error(err));
//     return request;
// };

// export const addBankAccount = async (data) => {
//     let request = await axios({
//         method: "post",
//         url: `${api.payment.bankAccount.addBankAccount}`,
//         headers: {
//             Authorization: `Bearer ${api.token}`,
//         },
//         data
//     }).catch(err => new Error(err));
//     return request;
// };

// export const getBankAccount = async () => {
//     let request = await axios({
//         method: "get",
//         url: `${api.payment.bankAccount.getBankAccount}`,
//         headers: {
//             Authorization: `Bearer ${api.token}`,
//         },
//     }).catch(err => new Error(err));
//     return request;
// };

// export const deleteBankAccount = async ({ bank_id }) => {
//     let request = await axios({
//         method: "delete",
//         url: `${api.payment.bankAccount.deleteBankAccount}`.repeat('{{bankId}}', bank_id),
//         headers: {
//             Authorization: `Bearer ${api.token}`,
//         },
//     }).catch(err => new Error(err));
//     return request;
// };

// export const addTransaction = async (data) => {
//     let request = await axios({
//         method: "post",
//         url: `${api.payment.transaction.addTransaction}`,
//         headers: {
//             Authorization: `Bearer ${api.token}`,
//         },
//         data
//     }).catch(err => new Error(err));
//     return request;
// };

// export const getAllTransactions = async ({ limit = 10, skip = 0 }) => {
//     let request = await axios({
//         method: "get",
//         url: `${api.payment.transaction.getAllTransaction}`.replace('{{limit}}', limit).replace('{{skip}}', skip),
//         headers: {
//             Authorization: `Bearer ${api.token}`,
//         },

//     }).catch(err => new Error(err));
//     return request;
// };
