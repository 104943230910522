import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { eye, eyeCross } from "../Base/SVG";
import useMediaQuery from "../Hooks/useMediaQuery";
import { resetPassword } from "../service/auth";

export default function ResetPassword({setIsAuth}) {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);
  const [mobImage, setMobImage] = useState(false);
  const width = useMediaQuery();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const emailFromUrl = queryParams.get("email");
  const tokenFromUrl = queryParams.get("token");

  const [form, setForm] = useState({
    email: emailFromUrl,
    token: tokenFromUrl,
    password: "",
    confirm_password: "",
  });

  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };

  const btnClick = async () => {
    
    try {
      let { data } = await resetPassword(
        form.email,
        form.token,
        form.password
      );
      if (form.password !== form.confirm_password) {
        setError(true);
        return;
      }
      if (data?.success) {
        navigate('/')
      } else {
        navigate('/error');
      }
    } catch (error) {
      // Redirect to the error page in case of an error
      navigate('/error');
    }
  };

  return (
    <div className="auth">
      <ToastContainer />
      <div className="auth__inner">
        <div className="auth__content">
          <div className="auth__content-inner">
          <Link to='/'>
            <div className="auth__content-logo">
              <img
                src={process.env.PUBLIC_URL + "/images/logo.svg"}
                alt="logo"
              />
            </div>
            </Link>
            <h3>Reset password</h3>
            <div className="authForm">
              <div className="input__outer">
                <label htmlFor="">Create New Password</label>
                <div
                  className={
                    "input pass " +
                    (form.password === "" && error ? "error" : "")
                  }
                >
                  <input
                    type={showPass ? "text" : "password"}
                    placeholder="Password"
                    value={form.password}
                    onChange={onChangeInput("password")}
                  />
                  {form.password === "" && error && (
                    <span className="error">Please enter your password</span>
                  )}
                  <button
                    className="inputEye"
                    type="button"
                    onClick={() => setShowPass(!showPass)}
                  >
                    {showPass ? eyeCross : eye}
                  </button>
                </div>
              </div>
              <div className="input__outer">
                <label htmlFor="">Confirm Password</label>
                <div
                  className={
                    "input pass " +
                    (form.confirm_password === "" && error ? "error" : "")
                  }
                >
                  <input
                    type={showPassConfirm ? "text" : "password"}
                    placeholder="Password"
                    value={form.confirm_password}
                    onChange={onChangeInput("confirm_password")}
                  />
                  {form.confirm_password === "" && error && (
                    <span className="error">Please enter your password</span>
                  )}
                  <button
                    className="inputEye"
                    type="button"
                    onClick={() => setShowPassConfirm(!showPassConfirm)}
                  >
                    {showPassConfirm ? eyeCross : eye}
                  </button>
                </div>
                {form.password !== form.confirm_password && (
                    <span className="errorText">Passwords do not match</span>
                  )}
              </div>
              <button
                type="button"
                className="button submit"
                onClick={btnClick}
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
        <div className="auth__image">
          {mobImage ? (
            <img
              src={process.env.PUBLIC_URL + "/images/signInMob.png"}
              alt="signInMob"
            />
          ) : (
            <img
              src={process.env.PUBLIC_URL + "/images/signIn.jpg"}
              alt="signIn"
            />
          )}
        </div>
      </div>
    </div>
  );
}