import React from "react";

export default function Sub() {
  return (
    <div className="profileMain__detail">
      <h6>Contact information</h6>
      <div className="profileMain__detail-info">
        <h6>Subscription</h6>
        <p>Since 01 November 2022</p>
      </div>
      <div className="profileMain__detail-info">
        <h6>Type</h6>
        <p>Free</p>
      </div>
    </div>
  );
}
