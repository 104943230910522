import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Auth from "./Auth/Auth";
import AppInner from "./AppInner";

export default function App() {
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuth(true);
    }
  }, []);
  const locationPage = useLocation();
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", appHeight);
  appHeight();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [locationPage]);

  return (
    <>
      <Routes>
        {!isAuth ? (
          <Route
            path="/*"
            element={<Auth setIsAuth={setIsAuth} />}
          />
        ) : (
          <Route
            path="/*"
            element={<AppInner setIsAuth={setIsAuth}/>}
          />
        )}
      </Routes>
    </>
  );
}