import React, { useState } from "react";
import Side from "./components/Side/Side";
import Main from "./components/Main/Main";
import Header from "../Base/Header";
import { inboxes } from "../data/data";

export default function Inbox({form}) {
  const [inbox, setInbox] = useState(inboxes[0]);
  return (
    <div className="inbox">
      <div className="auto__container">
        <Header title="Inbox" text="Find the most recent notification" form={form}/>
        <div className="inbox__inner">
             
        <Side inbox={inbox} setInbox={setInbox} />
          <Main inbox={inbox} />
        </div>
      </div>
    </div>
  );
}
