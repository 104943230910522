import React ,{useState,useEffect}from "react";
import Cheque from "./components/Cheque";
import { Link } from "react-router-dom";
import moment from "moment";
import { getAllTransactions } from "../../../service/payment";

export default function Step2({ pos,exportRef }) {
  const totalFunc = (amount, fees, delivery_cost) => {
    return parseFloat(amount) + parseFloat(fees) + parseFloat(delivery_cost);
  };
  const [referenceId, setReferenceId] = useState(null);

  const fetchReferenceId = async () => {
    try {
      const transactionsData = await getAllTransactions();
      const matchingTransaction = transactionsData.data.data.find(
        (transaction) => transaction.transaction_id === pos.transaction_id
      );
      if (matchingTransaction) {
        setReferenceId(matchingTransaction.id);
      } else {
        console.warn(
          "No matching transaction found for pos.transaction_id:",
          pos.transaction_id
        );
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  useEffect(() => {
    fetchReferenceId();
  }, []);

  return (
    <div className="formm">
      <div className="formm__inner">
        <div className="formm__info">
          <h5 className="sm">Reference</h5>
          <h5>{referenceId}</h5>
        </div>
        <div className="formm__info">
          <h5 className="sm">Amount</h5>
          <h5> ${totalFunc(pos.amount, pos.fees, pos.delivery_cost)} CAD</h5>
        </div>
        <div className="formm__info">
          <h5 className="sm">Issued Date</h5>
          <h5>{moment(pos.issue_date).format("DD/MM/YYYY")}</h5>
        </div>
        <div className="formm__info">
          <h5 className="sm">Delivery Date</h5>
          <h5>{moment(pos.delivery_date).format("DD/MM/YYYY")}</h5>
        </div>
        <div className="formm__info">
          <h5 className="sm">Memo</h5>
          <h5>{pos.memo_text}</h5>
        </div>
        {/* <div className="formm__info">
          <h5 className="sm">Transaction ID</h5>
          <h5>{pos.transaction_id}</h5>
        </div> */}
      </div>
      <div className="cheque__outer">

{
  pos.payment_type.value==='Cheque Delivery' &&(
    <div>
      <h5 className="uniq">Cheque:</h5>
      {
        pos.payment_type.value==='Cheque Delivery' && 
        <Cheque  exportRef={exportRef} pos={pos} />
      }
      </div>
  )
}

       
      </div>
      <Link to="/" className="button green big">
        Return Home
      </Link>
    </div>
  );
}