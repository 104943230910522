import React, { useState } from "react";
import ChooseImage from "./Avatar/ChooseImage";
import { penIcon, tickIcon } from "../../Base/SVG";

export default function Info({ updateForm, form, onChangeInput }) {
  const [edit, setEdit] = useState(false);

  return (
    <div className="profileInfo">
      <ChooseImage imageData={form.avatar} updateForm={updateForm} />
      <div className="profileInfo__content">
        <h4 className="big">
          {form.first_name} {form.surname}
        </h4>
        <p className="big">
          {edit ? (
            <>
              <input
                type="text"
                value={form.status}
                onChange={onChangeInput("status")}
              />
            </>
          ) : (
            <>
              <span>
                {`${form.status === true ? "online" : form.status}`}
              </span>
            </>
          )}
          <button type="button" onClick={() => setEdit(!edit)}>
            {edit ? tickIcon : penIcon}
          </button>
        </p>
      </div>
    </div>
  );
}
