import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { eye, eyeCross } from "../../../Base/SVG";
import { signUp } from "../../../service/auth";
import TermsConditions from "../../../TermsConditions";
import Autocomplete from "react-google-autocomplete";

export default function Step2({
  setStep,
  userData,
  setuserData,
  preFilledEmail,
  setIsAuth,
}) {
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState(preFilledEmail || "");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [checked, setChecked] = useState(false);
  const [phone, setPhone] = useState("");
  const [error, setError] = useState({
    email: false,
    fullName: false,
    password: false,
    terms: false,
    address: false,
    phone: false, 
  });
  const [showTermsModal, setShowTermsModal] = useState(false);

  useEffect(() => {
    // console.log("Updated userData is:", userData);
  }, [userData]);
// console.log("address",address.formatted_address)
  const onNextStep = async () => {
    if (!checked) {
      setError({ ...error, terms: true });
      return;
    }
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (
      !hasUpperCase ||
      !hasLowerCase ||
      !hasDigit ||
      !hasSpecialChar ||
      password.length < 8
    ) {
      setError({ ...error, password: true });
      return;
    }

    if (
      email?.length === 0 ||
      address?.length >= 20 ||
      fullName?.length === 0 ||
      password?.length === 0||
      phone?.length !== 10

    ) {
      setError({
        email: email?.length === 0,
        address: address?.address?.length >= 20,
        fullName: fullName?.length === 0,
        password: password?.length === 0,
        terms: !checked,
        phone: phone?.length !== 10
      });
      return;
    }

    const updatedUserData = {
      ...userData,
      first_name: fullName.split(" ")[0],
      surname: fullName.split(" ")[1],
      issued_id_number: "ABC123",
      email: email,
      phone: phone,
      address: address?.formatted_address,
      password: password,
      two_step_auth: false,
    };

    setuserData(updatedUserData);
  
    try {
      let { data } = await signUp(updatedUserData);
      if (data?.success) {
        localStorage.setItem("token", data?.data?.AccessToken);
        setIsAuth(true);
        navigate("/");
      } else {
        toast.error("Signup failed. Retry!");
      }
    } catch (error) {
      console.error("Error during signup:", error);
    }
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
    setShowTermsModal(true);
    setError({ ...error, terms: false });
  };

  const closeTermsModal = () => {
    setShowTermsModal(false);
    setChecked(false);
  };
  const setCheckedClose = () => {
    setShowTermsModal(false);
  };

  return (
    <>
      <ToastContainer />
      <div className="auth__content-head">
        <h3>Get started</h3>
        <Link to="/">
          <div className="auth__content-logo">
            <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" />
          </div>
        </Link>
      </div>
      <div className="step">
        <div className="step__body">
          <h5>Enter an email address</h5>
          <div className="input__outer">
            <label htmlFor="">Email Address</label>
            <div className={error.email ? "input error" : "input"}>
              <input
                type="email"
                placeholder={"Email or username"}
                value={preFilledEmail}
                onChange={(e) => setEmail(e.target.value)}
                disabled={true}
              />
            </div>
          </div>
          <div className="input__outer">
            <label htmlFor="">Phone Number</label>
            <div className={error.phone ? "Phone No. should be 10 digit" : "input"}>
              <input
                type="text"
                placeholder={"Phone Number"}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          {/* <div className="input__outer">
            <label htmlFor="">Address </label>
            <div className={error.address ? "input error" : "input"}>
              <input
                type="name"
                placeholder="Enter Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div> */}
          <div className="input__outer">
            <label htmlFor="">Address</label>
            <div className={"input " + (error.address ? "error" : "")}>
              <Autocomplete
                apiKey="AIzaSyCmLbwSJ3Ef4cyy_r2X1i9jQz9rca60P2I"
                options={{
                  types: ["address"],
                }}
                value={address.address}
                onChange={(e) => setAddress(e.target.value)}
                onPlaceSelected={(place) => { setAddress(place)}}
              />
              {error.address && (
                <span className="errorM">This field is required</span>
              )}
            </div>
          </div>
          <h5>Personal Information</h5>
          <div className="input__outer">
            <label htmlFor="">Full Name </label>
            <div className={error.fullName ? "input error" : "input"}>
              <input
                type="name"
                placeholder="Enter Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
          </div>
          <div className="input__outer">
            <label htmlFor="">Create Password </label>
            <div className={error.password ? "input error" : "input"}>
              <input
                type={showPass ? "text" : "password"}
                placeholder="Create Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="inputEye"
                type="button"
                onClick={() => setShowPass(!showPass)}
              >
                {showPass ? eyeCross : eye}
              </button>
            </div>
            {error.password && (
              <p style={{ color: "red" }}>
                Password must contain at least 8 characters, including
                uppercase, lowercase, digit, and special character.
              </p>
            )}
          </div>

          <div>
            <div className="check">
              <input
                type="checkbox"
                checked={checked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="" className="">
                Terms & Conditions
              </label>
              {error.terms && (
                <p style={{ color: "red" }}>
                  You must agree to the terms and conditions.
                </p>
              )}
            </div>
            {checked && showTermsModal && (
              <TermsConditions
                closeModal={closeTermsModal}
                setChecked={setCheckedClose}
              />
            )}
          </div>
        </div>
        <div className="step__foot">
          <div className="step__foot-text">Steps 2/2</div>
          <div className="step__foot-dots">
            <span className="active"></span>
            <span></span>
          </div>
          <button
            type="button"
            className="button submit"
            onClick={() => onNextStep()}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
