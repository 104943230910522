import React from "react";
import { Link } from "react-router-dom";
import {
  addRemove,
  chevronRight,
  customerSupport,
  updateProfile,
} from "../../../Base/SVG";

export default function Quick() {
  return (
    <div className="overviewSide__quick">
      <h4>Quick Action</h4>
      <Link to="/profile" className="overviewSide__quick-link">
        <span className="icon">{updateProfile}</span>
        Update Profile
        <span className="chevron">{chevronRight}</span>
      </Link>
      <Link to="/contract" className="overviewSide__quick-link">
        <span className="icon">{addRemove}</span>
        Add / Remove a Contract
        <span className="chevron">{chevronRight}</span>
      </Link>
      <Link to="/wallet" className="overviewSide__quick-link">
        <span className="icon">{addRemove}</span>
        Add / Remove a Credit Card
        <span className="chevron">{chevronRight}</span>
      </Link>
      <Link to="https://payque.ca/contact-us/ " className="overviewSide__quick-link">
        <span className="icon">{customerSupport}</span>
        Customer Support
        <span className="chevron">{chevronRight}</span>
      </Link>
    </div>
  );
}
