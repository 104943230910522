import React, { useEffect, useState } from "react";
import Info from "./components/Info";
import Detail from "./components/Detail";
import axios from "axios";
import Sub from "./components/Sub";
import { getProfile } from "../service/user";
import { ToastContainer, toast } from "react-toastify";
import api from "../config/api";
import Identity from "./components/Identity";

export default function Profile({ form, setForm, updateForm }) {
  const [tab, setTab] = useState("edit");

  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };

  const [kycStatus, setKycStatus] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const fetchKycData = async () => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: api.user.userKyc,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.request(config);
      console.log(response.data);
      if (response.data.success) {
        setKycStatus(response.data.data.status);
        setUploadedImage(response.data.data.photo_id);
      }
    } catch (error) {
      console.log("error in fetching kyc data", error);
    }
  };

  useEffect(() => {
    fetchKycData();
  }, []);

  return (
    <div className="profile">
      <ToastContainer />
      <div className="auto__container">
        <Info
          form={form}
          updateForm={updateForm}
          onChangeInput={onChangeInput}
        />
        <div className="profile__inner">
          <div className="profileSide">
            <button
              type="button"
              onClick={() => setTab("edit")}
              className={"profileSide__btn " + (tab === "edit" ? "active" : "")}
            >
              Edit Profile
            </button>
            <button
              type="button"
              onClick={() => setTab("identity")}
              className={"profileSide__btn " + (tab === "identity" ? "active" : "")}
            >
              Identity {kycStatus ? "" : "(Not Verified)"}
            </button>
            <button
              type="button"
              onClick={() => setTab("sub")}
              className={"profileSide__btn " + (tab === "sub" ? "active" : "")}
            >
              Subscription
            </button>
          </div>
          <div className="profileMain">
            {tab === "edit" && (
              <Detail
                form={form}
                onChangeInput={onChangeInput}
                updateForm={updateForm}
              />
            )}
            {tab === "sub" && <Sub />}
            {
              tab === "identity" && <Identity />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
