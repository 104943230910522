import React, { useState } from "react";

import DatePicker from "react-datepicker";

export default function DateInput({
  placeholder,
  pos,
  updatePos,
  type,
  error,
}) {
  const onChange = (date) => {
    updatePos({ [type]: date });
    setFocus(false);
  };
  const [focus, setFocus] = useState(false);
  return (
    <div
      className={
        "input inputDate " +
        (focus ? "active " : "") +
        (error && pos?.[type] === "" ? "error" : "")
      }
    >
      <DatePicker
        selected={pos?.[type]}
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        startDate={pos?.[type]}
        placeholderText={placeholder}
      />
      {error && pos?.[type] === "" && (
        <span className="errorM">This field required</span>
      )}
    </div>
  );
}
