import React, { useState } from "react";
import { eye, eyeCross } from "../../Base/SVG";
import { updateProfile } from "../../service/user";
import { ToastContainer,toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function Detail({ onChangeInput, form, updateForm }) {
  const [showPass, setShowPass] = useState(false);

  const handleUpdate = async()=>{
    const payload = {
      first_name : form.first_name,
      surname:form.surname,
      phone:form.phone,
      issued_id_number:form.issued_id_number,
      email:form.email,
      avatar: form.avatar,
      address:form.address,
      status:form.status,
      two_step_auth:form.two_step_auth,
      stripe_customer_id:form.stripe_customer_id,
      created_at:form.created_at,
      updated_at: new Date
    }
    const response = await updateProfile(payload,form.id);
    if(response.status === 202){
      toast.success("Profile updated!")
    }else{
      toast.error("Try again later")
    }
  }

  return (
    <>

      <div className="profileMain__detail">
        
        <h6>Contact information</h6>
        <div className="inputSm__row">
          <div className="inputSm__outer">
            <label htmlFor="">First Name</label>
            <div className="inputSm">
              <input
                type="text"
                placeholder="First Name"
                value={form.first_name}
                onChange={onChangeInput("first_name")}
              />
            </div>
          </div>
          <div className="inputSm__outer">
            <label htmlFor="">Email</label>
            <div className="inputSm">
              <input
                type="email"
                placeholder="Email"
                value={form.email}
                onChange={onChangeInput("email")}
                disabled={true}
              />
            </div>
          </div>
          <div className="inputSm__outer">
            <label htmlFor="">Surname</label>
            <div className="inputSm">
              <input
                type="text"
                placeholder="Surname"
                value={form.surname}
                onChange={onChangeInput("surname")}
              />
            </div>
          </div>
          <div className="inputSm__outer">
            <label htmlFor="">Address</label>
            <div className="inputSm">
              <input
                type="text"
                placeholder="Address"
                value={form.address}
                onChange={onChangeInput("address")}
              />
            </div>
            <p style={{paddingTop:10}}>Please Ensure that your address in your profile is correct as we may send important financial documents.</p>
          </div>
        </div>
      </div>
      <div className="profileMain__detail">
        <h6>Security</h6>
        <div className="inputSm__row">
        
          <div className="inputSm__outer">
            <label htmlFor="">2-Step Authentication</label>
            <div className="switch">
              <input
                type="checkbox"
                checked={form.two_step_auth}
                onChange={(e) =>
                  updateForm({
                    two_step_auth: e.target.checked,
                  })
                }
              />
              <label htmlFor="">
                <span></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <button type="button" className="button green" onClick={handleUpdate}>
        Save
      </button>
    </>
  );
}
