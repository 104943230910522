import React, { useEffect, useState } from "react";
import Main from "./components/Main/Main";
import Side from "./components/Side/Side";

export default function Wallet({ form, updateForm,profile }) {
  const [create, setCreate] = useState(null);
  const [editCard, setEditCard] = useState(null);
  useEffect(() => {

    if (create === null) {
      document.body.classList.remove("active");
    } else {
      document.body.classList.add("active");
    }
  }, [create]);


  return (
    <div className="wallet">
      <div className="auto__container">
        <div className="wallet__inner">
          <Main
            editCard={editCard}
            form={form}
            profile={profile}
            setEditCard={setEditCard}
            setCreate={setCreate}
            updateForm={updateForm}
          />
          <Side
            form={form}
            updateForm={updateForm}
            create={create}
            editCard={editCard}
            setCreate={setCreate}
            setEditCard={setEditCard}
          />
        </div>
      </div>
    </div>
  );
}
