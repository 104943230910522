import React from "react";
import { Link } from "react-router-dom";

export default function HeaderMob({ menu, setMenu }) {
  return (
    <div className="headerMob">
      <div className="auto__container">
        <div className="headerMob__inner">
          <Link to="/" className="headerMob__inner-logo">
            <img
              src={process.env.PUBLIC_URL + "/images/logoWhite.svg"}
              alt="logo"
            />
          </Link>
          <div
            className={"burger " + (menu ? "active" : "")}
            onClick={() => {
              setMenu(!menu);
            }}
          >
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
}
