import React from "react";
import Create from "./Create";
import Edit from "./Edit";
import { AnimatePresence } from "framer-motion";
import { ToastContainer } from "react-toastify";
import ParentComponent from "./Create";

export default function Side({
  form,
  setCreate,
  create,
  updateForm,
  editCard,
  setEditCard,
}) {
  return (
    <div className="walletSide">
      <ToastContainer/>
      <div className="walletSide__inner">
        <AnimatePresence>
          {create === "create" ? (
            <ParentComponent
              create={create}
              form={form}
              setCreate={setCreate}
              updateForm={updateForm}
            />
          ) : create === "edit" ? (
            <Edit
              create={create}
              editCard={editCard}
              setEditCard={setEditCard}
              form={form}
              setCreate={setCreate}
              updateForm={updateForm}
            />
          ) : (
            ""
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
