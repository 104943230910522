import React, { useState, useEffect } from "react";
import Pay from "./components/Pay";
import Type1 from "./components/Type1";
import Type2 from "./components/Type2";
import CustomSelect from "./components/CustomSelect";
import Cheque from "./components/Cheque";
import Type3 from "./components/Type3";
import { formatNumber } from "../../../Base/globalFunc";
import { addTransaction, getTransactionFee } from "../../../service/payment";
import { ToastContainer, toast } from "react-toastify";
import TermsConditions from "../../../TermsConditions";

export default function Step1({
  setSteps,
  form,
  updateForm,
  updatePos,
  pos,
  onChangeInput,
  setPayNow,
  error,
  setError,
}) {
  const [cardError, setcardError] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [checked, setChecked] = useState(false);

  const openConfirmationModal = () => {
    let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,15}$/;
    let postalCodeRegex =
      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/;

    const isInvalid =
      pos.card_id === "" ||
      pos.pay === "" ||
      pos.amount === "" ||
      pos.amount_words === "" ||
      pos.delivery_date === "" ||
      pos.memo_reason === "" ||
      pos.memo_text === "" ||
      (pos.payment_type.type === "Cheque Delivery" &&
        (pos.full_name === "" ||
          pos.paper_check.address === "" ||
          (pos.email === "" && pos.phone_number === "") ||
          (pos.email !== "" && !emailRegex.test(pos.email)) ||
          pos.paper_check.city === "" ||
          pos.paper_check.zip === "" ||
          pos.paper_check.province === "" ||
          (pos.paper_check.zip !== "" &&
            !postalCodeRegex.test(pos.paper_check.zip)))) ||
      (pos.payment_type.type === "e-interac" &&
        (pos.full_name === "" ||
          pos.e_interac.cell === "" ||
          pos.e_interac.email === "")) ||
      (pos.payment_type.type === "ACH Bank Transfer (Wire)" &&
        (pos.wire.companyName === "" ||
          pos.wire.firstName === "" ||
          pos.wire.branchNumber === "" ||
          pos.wire.accountNumber === "")) ||
      !(checked && pos.terms);

    if (isInvalid) {
      if (pos.card_id === "") {
        toast.error("Please select a card");
      } else {
        toast.error("Please fill in all required fields.");
      }
    } else {
      setShowConfirmationModal(true);
    }
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const fetchData = async (pos) => {
    try {
      let transactionTypeValue = pos.payment_type.value;
      if (transactionTypeValue === "Cheque Delivery") {
        transactionTypeValue = "cheque";
      } else if (transactionTypeValue === "e-interac") {
        transactionTypeValue = "e-interac";
      } else if (transactionTypeValue === "ACH Bank Transfer (Wire)") {
        transactionTypeValue = "wire";
      }

      const response = await getTransactionFee({
        transactionType: transactionTypeValue,
        amount: pos.amount,
      });
      if (response.data.success) {
        const fee = response?.data?.data?.fee;
        const total_payment = response?.data?.data?.total_payment;
        updatePos({
          fees: fee.toString(),
        });
        // const deliveryCost = response.data.data.delivery_cost;
        let newTotal = 0;
        if (transactionTypeValue === "cheque") {
          newTotal = Number(total_payment);
        } else if (transactionTypeValue === "wire") {
          newTotal = Number(total_payment);
        } else if (transactionTypeValue === "e-interac") {
          newTotal = Number(total_payment);

        }

        updatePos({
          total_amount: newTotal.toString(),
        });
      } else {
        console.error("Error fetching transaction fee:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching transaction fee:", error);
    }
  };
  useEffect(() => {
    fetchData(pos);
  }, [pos.fees, pos.payment_type.value]);

  const addOrder = async () => {
    let transactionTypeValue = pos.payment_type.value;
    if (transactionTypeValue === "Cheque Delivery") {
      transactionTypeValue = "cheque";
    } else if (transactionTypeValue === "e-interac") {
      transactionTypeValue = "e-interac";
    } else if (transactionTypeValue === "ACH Bank Transfer (Wire)") {
      transactionTypeValue = "wire";
    }
    try {
      const response = await addTransaction({
        card_id: pos.card_id,
        actual_amount: parseFloat(pos.amount),
        charges: parseFloat(pos.fees),
        total_amount: parseFloat(pos.total_amount),
        payee_account_id: pos.payee_id,
        memo: pos.memo_text,
        description: pos.description,
        delivery_type: transactionTypeValue,
        is_recurring_payment: pos.IsRecurringPayment,
        recurring_stop_date: pos.ReccuringStopDate,
      });

      if (response?.status === 202) {
        toast.success("Transaction success!");
        let arr = [...form.payment, pos];
        const transactionId = response.data.data.id;
        updatePos({ transaction_id: transactionId });
        updateForm({ payment: arr });
        setPayNow(null);
        setError(false);
        setSteps("step2");
      } else if (response.status === 500) {
        toast.success("Internal Server Error");
        console.error("Transaction failed. Status: ", response.status);
      }
    } catch (error) {
      console.error("Error during transaction:", error);
    }
  };
  const changeDelivery = (item) => {
    if (item) {
      if (item.value === "Deliver to a different address") {
        updatePos({
          full_name: "",
          phone_number: "",
          email: "",
          paper_check: {
            address: "",
            province: "Alberta",
            city: "",
            country: "Canada",
            zip: "",
          },
          delivery_check: item.value,
        });
      } else {
        updatePos({
          full_name: item.paying_for,
          phone_number: item.phone_number,
          email: item.email,
          paper_check: item.paper_check,
          delivery_check: item.value,
        });
      }
    }
  };
  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
    setShowTermsModal(true);
  };

  const closeTermsModal = () => {
    setShowTermsModal(false);
    setChecked(false);
  };
  const setCheckedClose = () => {
    setShowTermsModal(false);
  };

  return (
    <>
      <Pay
        error={error}
        form={form}
        updatePos={updatePos}
        pos={pos}
        onChangeInput={onChangeInput}
      />
      <ToastContainer />
      {pos.payment_type.value === "Cheque Delivery" && (
        <div className="formm">
          <div className="cheque__outer">
            <h5>Cheque Preview</h5>
            <Cheque updatePos={updatePos} pos={pos} />
          </div>
        </div>
      )}
      <div className="formm">
        <div className="formm__inner">
          {pos.payment_type.value === "Cheque Delivery" && (
            <div className="input__outer">
              <label htmlFor="">Cheque Delivery</label>
              <CustomSelect
                value={pos.delivery_check}
                error={error}
                onChange={changeDelivery}
                list={[
                  ...form.companies.map((item) => {
                    return { ...item, value: item.paying_for };
                  }),
                  {
                    id: "1",
                    value: "Deliver to a different address",
                  },
                ]}
                selected={""}
              />
            </div>
          )}
          {pos.payment_type.value !== "ACH Bank Transfer (Wire)" && (
            <div className="input__outer">
              <label htmlFor="">Full Name</label>
              <div
                className={
                  "input " + (error && pos.full_name === "" ? "error" : "")
                }
              >
                <input
                  type="text"
                  value={pos.pay}
                  onChange={onChangeInput("pay")}
                  placeholder="e.g John Smith"
                />
                {error && pos.full_name === "" && (
                  <span className="errorM">This field required</span>
                )}
              </div>
            </div>
          )}

          {pos.payment_type.value === "Cheque Delivery" && (
            <>
              <div className="input__outer">
                <label htmlFor="">Phone Number</label>
                <div
                  className={
                    "input " +
                    (error && pos.phone_number === "" && pos.email === ""
                      ? "error"
                      : "")
                  }
                >
                  <input
                    value={pos.phone_number}
                    onChange={(e) =>
                      updatePos({ phone_number: formatNumber(e.target.value) })
                    }
                    type="text"
                    placeholder="XXX-XXX-XXXX"
                  />
                  {error && pos.phone_number === "" && pos.email === "" && (
                    <span className="errorM">This field required</span>
                  )}
                </div>
              </div>
              <div className="input__outer">
                <label htmlFor="">Email Address</label>

                <div
                  className={
                    "input " +
                    ((error && pos.phone_number === "" && pos.email === "") ||
                      (error &&
                        pos.email !== "" &&
                        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,15}$/.test(pos.email) ===
                        false)
                      ? "error"
                      : "")
                  }
                >
                  <input
                    value={pos.email}
                    onChange={onChangeInput("email")}
                    type="email"
                    placeholder="john.smith@internet.com"
                  />
                  {error && pos.phone_number === "" && pos.email === "" ? (
                    <span className="errorM">This field required</span>
                  ) : error &&
                    pos.email !== "" &&
                    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,15}$/.test(pos.email) ===
                    false ? (
                    <span className="errorM">Invalid Email address</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )}

          {pos.payment_type.value === "Cheque Delivery" && (
            <Type1 error={error} updatePos={updatePos} pos={pos} />
          )}
          {pos.payment_type.value === "e-interac" && (
            <Type2 error={error} updatePos={updatePos} pos={pos} form={form} />
          )}
          {pos.payment_type.value === "ACH Bank Transfer (Wire)" && (
            <Type3 error={error} updatePos={updatePos} pos={pos} form={form} />
          )}
        </div>
      </div>
      <div className="check">
        <input
          value={pos.terms}
          onChange={(e) => {
            updatePos({ terms: e.target.checked });
          }}
          type="checkbox"
        />
        <div className="check">
          <input
            type="checkbox"
            checked={checked}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="" className="">
            Terms & Conditions
          </label>{" "}
          {error && checked === false && (
            <p style={{ color: "red" }}>
              Please agree to the terms and conditions!
            </p>
          )}
        </div>
        {checked && showTermsModal && (
          <TermsConditions
            closeModal={closeTermsModal}
            setChecked={setCheckedClose}
          />
        )}
      </div>
      <button
        type="button"
        className="button green big"
        onClick={openConfirmationModal}
      >
        {pos.payment_type.value === "Cheque Delivery" && <p>Order Cheque</p>}
        {pos.payment_type.value === "e-interac" && <p>e-Transfer now</p>}
        {pos.payment_type.value === "ACH Bank Transfer (Wire)" && (
          <p>Wire now</p>
        )}
        {error && checked === "false" && (
          <span className="errorM">This field required</span>
        )}
      </button>
      {showConfirmationModal && (
        <ConfirmationModal
          closeModal={closeConfirmationModal}
          confirmOrder={addOrder}
          pos={pos}
        />
      )}
    </>
  );
}
const ConfirmationModal = ({ closeModal, confirmOrder, pos }) => (
  <div className="modal-overlay">
    <div className="modal">
      <p className="confirmation-text">Approve Payment</p>
      <p style={{ color: "red" }}>
        Before proceeding with the payment, please ensure that you have reviewed
        the following details carefully, including the recipient and intended
        amount, to ensure accuracy.
      </p>
      <div className="confirmation-details">
        <p>Selected Card: {pos.card_name}</p>
        <p>Amount: ${isNaN(parseFloat(pos.total_amount)) ? '0.00' : parseFloat(pos.total_amount).toFixed(2)}</p>

        <p>Payee: {pos.pay}</p>
        <p>Memo Text: {pos.memo_text}</p>
      </div>
      <div className="confirmation-buttons">
        <button className="confirmation-button cancel" onClick={closeModal}>
          Cancel
        </button>
        <button className="confirmation-button confirm" onClick={confirmOrder}>
          {pos.payment_type.value === "Cheque Delivery" && <p>Order Cheque</p>}
          {pos.payment_type.value === "e-interac" && <p>e-Transfer now</p>}
          {pos.payment_type.value === "ACH Bank Transfer (Wire)" && (
            <p>Wire now</p>
          )}
        </button>
      </div>
    </div>
  </div>
);
