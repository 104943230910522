import React from "react";
import Header from "../Base/Header";
import Side from "./components/Side/Side";
import Main from "./components/Main/Main";

export default function Overview({form,profile}) {
  return (
    <div className="overview">
      <div className="auto__container">
        <Header
          title="Account Overview"
          text="View your dashboard with recent information"
          form = {profile}
        />
        <div className="overview__inner">
          <Main />
          <Side form={form}/>
        </div>
      </div>
    </div>
  );
}
