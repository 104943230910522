export const readFile = ({ file, onStart, onLoad, onLoadend }) => {
  if (file) {
    var reader = new FileReader();
    onStart();
    reader.onload = ({ target }) => onLoad(target.result);
    reader.onloadend = () => onLoadend();
    reader.readAsDataURL(file);
  }
};
export const createForm = (file) => {
  let formData = new FormData();
  formData.append("file", file);
  return formData;
};
