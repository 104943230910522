import React, { useEffect, useState } from "react";
import { chevronRight, minusIcon, plusIcon, userIcon } from "../../../Base/SVG";
import { motion, AnimatePresence } from "framer-motion";
import { json, useNavigate } from "react-router-dom";
import { getPayee } from "../../../service/user";
import { getCards } from "../../../service/payment";

export default function Side({ form, deletePayee, editPayee, setPayNow, updateForm }) {

  const [isShown, setIsShown] = useState(true);
  const navigate = useNavigate();
  const payNowHandler = (item) => {
    setPayNow({
      id: "",
      pay: item.paying_for,
      amount: "",
      amount_words: "",
      issue_date: "",
      delivery_date: "",
      memo_reason: {
        id: "1",
        value: "Rental",
      },
      memo_text: "",
      fees: 0,
      delivery_cost: 0,
      payment_type:
        item?.payment_type?.type === "paper_check"
          ? {
            id: "1",
            value: "Cheque Delivery",
          }
          : item.payment_type.type === "e_interac"
            ? {
              id: "2",
              value: "e-interac",
            }
            : item.payment_type.type === "ach_bank_transfer"
              ? {
                id: "3",
                value: "ACH Bank Transfer (Wire)",
              }
              : "",

      delivery_check: "",
      full_name: item.paying_for || "",
      phone_number: item.phone_number || "",
      email: item.email || "",
      terms: true,
      paper_check: {
        address: item.address || "",
        province: item.province || "",
        city: item.city || "",
        country: item.country || "",
        zip: item.zip || "",
      },
      e_interac: {
        cell: item.e_interac_cell || "",
        email: item.email || "",
      },
      wire: {
        companyName: item?.wire?.companyName || "",
        firstName: item?.wire?.firstName || "",
        branchNumber: item?.wire?.branchNumber || "",
        accountNumber: item?.wire?.accountNumber || "",
      },
      cell: item.e_interac_cell || "",
      email: item.email || "",
    });
    navigate("/make-payment");
  };
  return (
    <div className="contractSide">
      <div
        className={"contractSide__head " + (isShown ? "active" : "")}
        onClick={() => setIsShown(!isShown)}
      >
        <h4>List of Payee</h4>
        {isShown ? minusIcon : plusIcon}
      </div>
      <AnimatePresence>
        {isShown && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, y: 10 }}
            className="contractSide__inner"
          >
            {form.companies.length !== 0 ? (
              <p className="uniq">List of all the payee</p>
            ) : (
              <p className="uniq">There is no payee yet</p>
            )}
            <div className="payee">
              {form?.companies?.map((item, index) => {
                return (
                  <PayeeItem
                    payNowHandler={() => payNowHandler(item)}
                    deletePayee={() => deletePayee(item)}
                    modifyPayee={() => editPayee(item)}
                    {...item}
                    key={index}
                  />
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
const PayeeItem = (props) => {
  const getRandomColor = () => {
    const colors = ['#FF5733', '#FF33E6', '#33FF5E', '#337CFF', '#FFC933'];
    return colors[Math.floor(Math.random() * colors.length)];
  };
  const renderAvatar = () => {
    const color = getRandomColor();
    return <div className="payeeItem__info-avatar" style={{ backgroundColor: color}}></div>;
  };
  
  return (
    <div className="payeeItem">
      <div className="payeeItem__info">
          {renderAvatar()}
        <div className="payeeItem__info-content">
          <h6 className="sm">{props.paying_for}</h6>
          {props.payment_type.type === "paper_check" && <span>Cheque</span>}
          {props.payment_type.type === "e_interac" && <span>e-interac</span>}
          {props.payment_type.type === "ach_bank_transfer" && <span>ACH Bank Transfer (Wire)</span>}
        </div>
      </div>
      <div className="payeeItem__btns">
        <button
          type="button"
          className="payeeItem__btn"
          onClick={props.payNowHandler}
        >
          Pay Now {chevronRight}
        </button>
        <button
          type="button"
          className="payeeItem__btn"
          onClick={props.modifyPayee}
        >
          Modify {chevronRight}
        </button>
        <button
          type="button"
          className="payeeItem__btn"
          onClick={props.deletePayee}
        >
          Delete {chevronRight}
        </button>
      </div>
    </div>
  );
};
