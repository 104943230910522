import React from "react";
import Details from "../Details";
import { chevronRight } from "../../../../../Base/SVG";

export default function Assets({ pos, exportAsImage, exportRef, Print }) {

  return (
    <div className="assets">
      <div className="assets__btns">
        <button
          type="button"
          className="assets__btn"
          onClick={() => Print(exportRef.current)}
        >
          Print {chevronRight}
        </button>
        <a href="mailto:" className="assets__btn">
          Email {chevronRight}
        </a>
        <button
          type="button"
          className="assets__btn"
          onClick={() => exportAsImage(exportRef.current, `ABX${pos.id}`)}
        >
          Download {chevronRight}
        </button>
      </div>
      <Details pos={pos} />
    </div>
  );
}
