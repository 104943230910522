// LineChart.js
import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  registerables as registerablesJS,
} from "chart.js";
import { lineGraph } from "../../../service/user";

ChartJS.register(...registerablesJS);

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function LineChart({ lineGraphData, labels }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        align: "start",
        labels: {
          boxWidth: 20,
          boxHeight: 0,
          padding: 10,
          font: "Quicksand",
        },
      },

      tooltip: {
        callbacks: {
          title: () => null,
          label: function (tooltipItem) {
            if (tooltipItem.chart.config.options.percentage) {
              let sum = 0;
              let dataArr = tooltipItem.dataset.data;
              dataArr.map((data) => {
                sum += Number(data);
              });
              let percentage =
                ((tooltipItem.parsed.y * 100) / sum).toFixed(2) + "%";
              return `${percentage}`;
            } else {
              var tooltipText = "";
              if (tooltipItem.dataset.data[tooltipItem.dataIndex] != null)
                tooltipText =
                  tooltipItem.dataset.data[tooltipItem.dataIndex].toString();
              return tooltipText;
            }
          },
          labelTextColor: function (context) {
            return "#000D57";
          },
        },
        displayColors: false,
        backgroundColor: "#FAFFFA",
        borderColor: "rgba(16, 38, 32, 0.15)",
        borderWidth: 1,
        yAlign: "bottom",
      },
    },
    elements: {
      point: {
        borderWidth: 0,
        radius: 10,
      },
      line: {
        tension: 0.5,
      },
    },
    scales: {
      y: {
        ticks: {
          callback: (label) => {
            if (label >= 1000) {
              return `${label / 1000}K`;
            } else {
              return label;
            }
          },
          font: { size: 10, family: "Quicksand" },
          color: "#1F203F",
          stepSize: 1000,
        },
        border: {
          display: false,
        },
        grid: {
          drawBorder: false,
          color: "rgba(0, 0, 0, 0)",
        },
        beginAtZero: true,
      },
      x: {
        border: {
          display: false,
        },
        ticks: {
          font: { size: 10, family: "Quicksand" },
          color: "#1F203F",
        },
        grid: {
          color: "rgba(0, 0, 0, 0)",
          drawBorder: false,
        },
        beginAtZero: true,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        type: "line",
        label: lineGraphData&&lineGraphData.map((datapoint) => datapoint.brand),
        data: lineGraphData&&lineGraphData.map((dataPoint) => dataPoint.amount),
        borderColor: "#0177FB",
        borderWidth: 1,
        pointBackgroundColor: "rgba(0,0,0,0)",
        pointBorderColor: "rgba(0,0,0,0)",
      },

    ],
  };

  return <Line options={options} data={data} />;
}
