import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useMediaQuery from "../../Hooks/useMediaQuery";
import { login, otpLogin } from "../../service/auth";
import OtpInput from 'react-otp-input';
import { toast } from "react-toastify";

// Function for OTP
export default function OtpInputScreen({ setIsAuth }) {
  const navigate = useNavigate()

  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState(false);
  const [mobImage, setMobImage] = useState(false);
  const location = useLocation();

  const { email, password } = location.state || {};
  const width = useMediaQuery();
  const [otp, setOtp] = useState('');


  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsAuth(true);
    }
  }, [])


  const btnClick = async () => {
    if (email === "" || password === "" || otp.length !== 5 ) setError(true);
    else {
      setError(false);
      try {
          

        let payload = {
          email: email,
          password: password,
          otp: otp
        }
        let { data } = await otpLogin(payload)
        console.log(data)

        // when verified
        if (data?.success) {
          localStorage.setItem('token', data?.data?.AccessToken)
          setIsAuth(true);
          navigate('/');
        } 

      } catch (error) {
        setError(true);
        console.log(error)

      }
    }
  };

  const resendOtp = async () => {
    try {
        if (email === "" || password === "") setError(true);
        else {
          setError(false);
          try {
            let payload = {
              email: email,
              password: password,
            };
            let { data } = await login(payload);
    
            // when verified
            if (data?.success) {
              // localStorage.setItem("token", data?.data?.AccessToken);
              // setIsAuth(true);
              setOtp('')
            } else {
              toast.error("Invalid email or password. Retry!");
            }
          } catch (error) {
            setError(true);
            console.log(error);
          }
        }
    } catch (error) {
        setError(true);
        console.log(error)
    }
  }

  useEffect(() => {
    if (width <= 1024) {
      setMobImage(true);
    } else setMobImage(false);
  }, [width]);
  return (
    <div className="auth">
      <div className="auth__inner">
        <div className="auth__content">
          <div className="auth__content-inner">
            <div className="auth__content-logo">
              <img
                src={process.env.PUBLIC_URL + "/images/logo.svg"}
                alt="logo"
              />
            </div>
            <h3>Verification OTP </h3>
            <div className="authForm">
              <div className="input__outer">
              <div className="margin-top--small">
                  <OtpInput
                    inputStyle="inputStyle"
                    numInputs={5}
                    renderSeparator={<span></span>}
                    value={otp}
                    placeholder={
                        '000000'
                    }
                    inputType={'number'}
                    renderInput={(props) => <input {...props} />}
                    shouldAutoFocus
                    onChange={(otp) => setOtp(otp)}
                  />
                </div>
              </div>
              <div className="margin-top--medium">
              <button
                type="button"
                className="button submit"
                onClick={btnClick}
              >
                Verify OTP
              </button>
              </div>    
              <div className="authForm__foot">
                <p>
                  Didn't Receive the OTP? <Link 
                  onClick={resendOtp}
                  >Resend</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="auth__image">
          {mobImage ? (
            <img
              src={process.env.PUBLIC_URL + "/images/signInMob.png"}
              alt="signInMob"
            />
          ) : (
            <img
              src={process.env.PUBLIC_URL + "/images/signIn.jpg"}
              alt="signIn"
            />
          )}
        </div>
      </div>
    </div>
  );
}
