import React, { useEffect, useState } from "react";
import Main from "./components/Main/Main";
import Side from "./components/Side/Side";
import moment from "moment";
import { createPayee, deletePayee, getPayee } from "../service/user";
import { ToastContainer, toast } from "react-toastify";
const defaultPos = {
  id: "",
  photo: "",
  avatar: "",
  contract_file: "",
  paying_for: "",
  phone_number: "",
  email: "",
  contract_number: "",
  contract_period: "",
  start_date: "",
  end_date: "",
  category: "",
  subCategory: "",
  payment_type: {
    type: "paper_check",
    hint: "(Standard Delivery is FREE * Expedited Delivery Available)",
    expect: "Expect in 2-11 Days",
  },

  paper_check: {
    answer: "",
    address: "",
    province: "Alberta",
    city: "",
    country: "Canada",
    zip: "",
  },
  e_interac: {
    cell: "",
    email: "",
  },
  wire: {
    companyName: "",
    firstName: "",
    branchNumber: "",
    accountNumber: "",
  },
};
export default function Contract({ form, updateForm, setPayNow,profile }) {
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState(false);
  const [pos, setPos] = useState({
    id: "",
    photo: "",
    avatar: "",
    contract_file: "",
    paying_for: "",
    phone_number: "",
    email: "",
    contract_number: "",
    contract_period: "",

    start_date: "",
    end_date: "",
    category: "",
    subCategory: "",
    payment_type: {
      type: "paper_check",
      hint: "(Standard Delivery is FREE * Expedited Delivery Available)",
      expect: "Expect in 2-11 Days",
    },

    paper_check: {
      answer: "",
      address: "",
      province: "",
      city: "",
      country: "",
      zip: "",
    },
    e_interac: {
      cell: "",
      email: "",
    },
    wire: {
      companyName: "",
      firstName: "",
      branchNumber: "",
      accountNumber: "",
    },
    address: "",
    province: "",
    city: "",
    country: "",
    zip: ""

  });

  const updatePos = (data) => {
    setPos((pos) => ({ ...pos, ...data }));
  };
  const onChangeInput = (input) => (e) => {
    setPos((pos) => ({ ...pos, [input]: e.target.value }));
  };
  const addPayee = async () => {
    console.log('inside add payee')
    let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,15}$/;
    let postalCodeRegex =
      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/;
    if (
      // pos.photo === "" ||
      // pos.avatar === "" ||
      pos.contract_file === "" ||
      pos.paying_for === "" ||
      (pos.email === "" && pos.phone_number === "") ||
      (pos.email !== "" && emailRegex.test(pos.email) === false) ||
      (pos.payment_type.type === "paper_check" &&
        (pos.paper_check.address === "" ||
          pos.paper_check.city === "" ||
          pos.paper_check.province=== "" ||
          pos.paper_check.zip === "" ||
          (pos.paper_check.zip !== "" && postalCodeRegex.test(pos.paper_check.zip) === false))) ||
      (pos.payment_type.type === "e-interac" &&
        // pos.e_interac.cell === "" &&
        pos.e_interac.email === "") ||
      (pos.payment_type.type === "ACH Bank Transfer (Wire)" &&
        (pos.wire.companyName === "" ||
          pos.wire.firstName === "" ||
          pos.wire.branchNumber === "" ||
          pos.wire.accountNumber === ""))
    ){
  console.log('inside if',pos.payment_type.type,pos.e_interac)
      setError(true);
    }
    else {
      let arr = [...form.companies, { ...pos, id: form.companies.length + 1 }];

      try {
        let payload
        if (pos.payment_type.type == 'paper_check') {
          payload = {
            "paying_for": pos.paying_for,
            "phone_number": pos.phone_number,
            "email": pos.email,
            "length_of_contract": pos.contract_number + ' ' + pos.contract_period?.value || '',
            "start_date": moment(pos.start_date).format('YYYY-MM-DD'),
            "end_date": moment(pos.end_date).format('YYYY-MM-DD'),
            "category": pos.category.value,
            "sub_category": pos.subCategory.value,
            "payment_method": pos.payment_type.type,
            "mail_check": "Yes",
            "address": pos.paper_check.address,
            "province": pos.paper_check.province,
            "city": pos.paper_check.city,
            "country": pos.paper_check.country,
            "zip": pos.paper_check.zip,
            "tenancy_contract": pos.contract_file,
            "photo_id": pos.avatar
          }
        }
        else if (pos.payment_type.type == "e-interac") {
          payload = {
            "paying_for": pos.paying_for,
            "phone_number": pos.phone_number,
            "email": pos.email,
            "length_of_contract": pos.contract_number + ' ' + pos.contract_period?.value,
            "start_date": moment(pos.start_date).format('YYYY-MM-DD'),
            "end_date": moment(pos.end_date).format('YYYY-MM-DD'),
            "category": pos.category.value,
            "sub_category": pos.subCategory.value,
            "payment_method": 'e_interac',
            "e_interac_cell": pos.e_interac.cell,
            "tenancy_contract": pos.contract_file,
            "photo_id": pos.avatar
          }
        }
        else {
          payload = {
            "paying_for": pos.paying_for,
            "phone_number": pos.phone_number,
            "email": pos.email,
            "length_of_contract": pos.contract_number + ' ' + pos.contract_period?.value,
            "start_date": moment(pos.start_date).format('YYYY-MM-DD'),
            "end_date": moment(pos.end_date).format('YYYY-MM-DD'),
            "category": pos.category.value,
            "sub_category": pos.subCategory.value,
            "payment_method": 'ach_bank_transfer',
            "last_name_or_company_name": pos.wire.companyName,
            "company_first_name": pos.wire.firstName,
            "rbc_branch_transit_number": pos.wire.branchNumber,
            "rbc_branch_account_number": pos.wire.accountNumber,
            "tenancy_contract": pos.contract_file,
            "photo_id": pos.avatar
          }
        }
        let { data } = await createPayee(payload)
        if (data.success) {
          toast.success("Payee created successfully!")
          updateForm({ companies: arr });
          setPos(defaultPos);
        }

      } catch (error) {
        console.log('error while create payee', error)
      }


      setError(false);
    }
  };

  const updatePayee = async () => {
    let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,15}$/;
    let postalCodeRegex =
      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/;
    if (
      // pos.photo === "" ||
      // pos.avatar === "" ||
      pos.contract_file === "" ||
      pos.paying_for === "" ||
      (pos.email === "" && pos.phone_number === "") ||
      (pos.email !== "" && emailRegex.test(pos.email) === false) ||
      (pos.payment_type.type === "paper_check" &&
        (pos.paper_check.address === "" ||
          pos.paper_check.city === "" ||
          pos.paper_check.zip === "" ||
          (pos.paper_check.zip !== "" && postalCodeRegex.test(pos.paper_check.zip) === false))) ||
      (pos.payment_type.type === "e-interac" &&
        // pos.e_interac.cell === "" &&
        pos.e_interac.email === "") ||
      (pos.payment_type.type === "ACH Bank Transfer (Wire)" &&
        (pos.wire.companyName === "" ||
          pos.wire.firstName === "" ||
          pos.wire.branchNumber === "" ||
          pos.wire.accountNumber === ""))
    ) {
      setError(true);
      console.log('inside if')
    } else {

      console.log('inside else')
      let arr = [...form.companies];
      const index = arr.map((item2) => item2.id).indexOf(pos.id);
      arr[index] = pos;


      try {

        let payload
        if (pos.payment_type.type == 'paper_check') {
          payload = {
            "paying_for": pos.paying_for,
            "phone_number": pos.phone_number,
            "email": pos.email,
            "length_of_contract": pos.contract_number + ' ' + pos.contract_period?.value || '',
            "start_date": moment(pos.start_date).format('YYYY-MM-DD'),
            "end_date": moment(pos.end_date).format('YYYY-MM-DD'),
            "category": pos.category.value,
            "sub_category": pos.subCategory.value,
            "payment_method": pos.payment_type.type,
            "mail_check": "Yes",
            "address": pos.paper_check.address,
            "province": pos.province,
            "city": pos.paper_check.city,
            "country": pos.paper_check.country,
            "zip": pos.paper_check.zip,
            "tenancy_contract": pos.contract_file,
            "photo_id": pos.avatar
          }
        }
        else if (pos.payment_type.type == "e_interac") {
          payload = {
            "paying_for": pos.paying_for,
            "phone_number": pos.phone_number,
            "email": pos.email,
            "length_of_contract": pos.contract_number + ' ' + pos.contract_period?.value,
            "start_date": moment(pos.start_date).format('YYYY-MM-DD'),
            "end_date": moment(pos.end_date).format('YYYY-MM-DD'),
            "category": pos.category.value,
            "sub_category": pos.subCategory.value,
            "payment_method": pos.payment_type.type,
            "e_interac_cell": pos.e_interac.cell,
            "tenancy_contract": pos.contract_file,
            "photo_id": pos.avatar,
          }
        }
        else {
          payload = {
            "paying_for": pos.paying_for,
            "phone_number": pos.phone_number,
            "email": pos.email,
            "length_of_contract": pos.contract_number + ' ' + pos.contract_period?.value,
            "start_date": moment(pos.start_date).format('YYYY-MM-DD'),
            "end_date": moment(pos.end_date).format('YYYY-MM-DD'),
            "category": pos.category.value,
            "sub_category": pos.subCategory.value,
            "payment_method": pos.payment_type.value,
            "last_name_or_company_name": pos.wire.companyName,
            "company_first_name": pos.wire.firstName,
            "rbc_branch_transit_number": pos.wire.branchNumber,
            "rbc_branch_account_number": pos.wire.accountNumber,
            "tenancy_contract": pos.contract_file,
            "photo_id": pos.avatar,
          }
        }

        let { data } = await createPayee(payload)
        if (data.success) {
          updateForm({ companies: arr });
          setPos(defaultPos);
          setError(false);
          setIsEdit(false);
        }
      } catch (error) {
        console.log('inside update payee', error)
      }

    }
  };
  const deletePayeeFilter = async (item) => {


    try {
      let payload = { payee_id: item.id }
      let { data } = await deletePayee(payload);
      if (data?.success) {
        toast.success("Payee deleted successfully")
        updateForm({
          companies: form.companies.filter((compItem) => compItem.id !== item.id),
        });
      } 
      setPos(defaultPos);
    } catch (error) {
      console.log('inside delete payee', error)
    }


  };
  const editPayee = (item) => {
    setPos(item);
    setIsEdit(item);
  };

  return (
    <div className="contract">
      <ToastContainer/>
      <div className="auto__container">
        <div className="contract__inner">
          <Main
            error={error}
            setError={setError}
            updatePos={updatePos}
            onChangeInput={onChangeInput}
            pos={pos}
            form={profile}
            payeeHandler={isEdit ? updatePayee : addPayee}
            isEdit={isEdit}

          />
          <Side
            setPayNow={setPayNow}
            editPayee={editPayee}
            deletePayee={deletePayeeFilter}
            form={form}
            updateForm={updateForm}
          />
        </div>
      </div>
    </div>
  );
}
