import React from "react";
import PhotoId from "./PhotoId";
import ContractDoc from "./ContractDoc";

export default function Uploads({ pos, updatePos, error, setError }) {
  return (
    <div className="formm">
      <div className="formm__inner">
        <div className="fileUp__box">
          <p>
            We need a supporting document for this payment so we can review for
            accuracy, and get your payment delivered on time.
          </p>
          <h6>Please upload:</h6>
          {/* <PhotoId error={error} imageData={pos.photo} updatePos={updatePos} /> */}
          <ContractDoc
            error={error}
            imageData={pos.contract_file}
            updatePos={updatePos}
          />
        </div>
      </div>
    </div>
  );
}
