import React, { useState } from "react";
import Details from "../Details";
export default function Summary({ form, pos,setPos }) {
  const [isActive, setIsActive] = useState(null);

  const handleItemClick = (item) => {
    setIsActive(item.id);
    console.log(item)
    setPos({
      ...pos,
      card_id: item.id,
      card_name: item.name,
    });
  };

  return (
    <div className="summary">
      <div className="summary__items">
      {form.cards.map((item) => {
          return (
            <div
              className={"summaryItem " + (isActive === item.id ? "active" : "")}
              key={item.id} 
              onClick={() => handleItemClick(item)}
            >
              <div className="summaryItem__info">
                <img src={item.cardImage} alt="" />
                <p>{item.cardType}</p>
              </div>
              <div className="summaryItem__content">
                <h6>{item.name}</h6>
                <p>*** {item.cardNumber}</p>
              </div>
            </div>
          );
        })}
      </div>
      <Details pos={pos} />
    </div>
  );
}
