// Main.js
import React, { useEffect, useState } from "react";
import History from "../../../Components/History";
import LineChart from "./LineChart";
import { lineGraph } from "../../../service/user";

export default function Main() {
  const [lineGraphData, setLineGraphData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await lineGraph();
      if (response.data.success) {
        setLineGraphData(response.data.data);
      } else {
        console.error("Error in API response:", response.message);
      }
    } catch (error) {
      console.error("Error fetching line graph data:", error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  const labels = lineGraphData!=null && lineGraphData.map((dataPoint) => dataPoint.date) || [
    "Dec 1",
    "Dec 2",
    "Dec 3",
    "Dec 4",
    "Dec 5",
    "Dec 6",
    "Dec 7",
  ];

  return (
    <div className="overviewMain">
      <div className="overviewMain__chart">
        <h5>Wallet Usage</h5>
        {lineGraphData && (
          <LineChart lineGraphData={lineGraphData} labels={labels} />
         )}
      </div>
      <History />
    </div>
  );
}

