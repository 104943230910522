let baseUrl = `https://api.payque.ca`;

export default {
  auth: {
    login: `${baseUrl}/auth/v1/login`,
    otpLogin: `${baseUrl}/auth/v1/otp-login`,
    googleLogin: `${baseUrl}/auth/v1/login/google`,
    verifyGoogle: `${baseUrl}/auth/v1/oauth/google/token`,
    forgot: `${baseUrl}/auth/v1/forgot-password`,
    reset: `${baseUrl}/auth/v1/reset-password?email={{email}}&token={{token}}&new_password={{new_password}}`,
    signUp: `${baseUrl}/auth/v1/sign-up`,
    verifyOtp: `${baseUrl}/auth/v1/:id/verify-otp`,
    sendOtp: `${baseUrl}/auth/v1/:id/send-otp`,
  },
  user: {
    userKyc: `${baseUrl}/user/v1/kyc`,
    getprofile: `${baseUrl}/user/v1/profile`,
    updateprofile: `${baseUrl}/user/v1/profile`,
    createPayee: `${baseUrl}/user/v1/payee`,
    getPayee: `${baseUrl}/user/v1/payee?page_size={{page_size}}&page_number={{page_number}}`,
    deletePayee: `${baseUrl}/user/v1/payee?payee_id={{payee_id}}`,
    uploadFile: `${baseUrl}/user/v1/upload`,
    lineGraph: `${baseUrl}/user/v1/dashboard/line-graph`,
    pieGraph: `${baseUrl}/user/v1/dashboard/pie-graph`,
  },
  payment: {
    card: {
      addCard: `${baseUrl}/user/v1/card`,
      getCard: `${baseUrl}/user/v1/card`,
      deleteCard: `${baseUrl}/user/v1/card?card_id={{cardId}}`,
    },
    bankAccount: {
      addBankAccount: `${baseUrl}/user/v1/bank-account`,
      getBankAccount: `${baseUrl}/user/v1/bank-account`,
      deleteBankAccount: `${baseUrl}/user/v1/bank-account?bank_id={{bankId}}`,
    },
    transaction: {
      addTransaction: `${baseUrl}/user/v1/transaction`,
      getAllTransaction: `${baseUrl}/user/v1/transaction?limit&skip`,
      transaction_fee: `${baseUrl}/user/v1/transaction-fee?transaction_type={{transactionType}}&amount={{amount}}`,
    },
  },
  token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3NfdXVpZCI6IjRmMTIxNjM3LWEyYWUtNDMzMi1iZGExLTk0ODcyZmNmNjY5NSIsImF1dGhvcml6ZWQiOnRydWUsImV4cCI6MTY5MzkxODMxNSwibWV0YV9kYXRhIjoic2h1YmhhbUBvcmdvc3lzLmNvbSJ9.-Bc_JRmT1XTQpvKQ31iQbLHDePqQEeD29RwbLd5xZhU",
};
