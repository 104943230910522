import React, { useEffect, useState } from "react";

export default function Type2({ pos, updatePos, error,isEdit }) {
  const [typeInfo, setTypeInfo] = useState(pos.e_interac);

  const onChangeInput = (input) => (e) => {
    setTypeInfo((typeInfo) => ({ ...typeInfo, [input]: e.target.value }));
  };

  useEffect(() => {
    updatePos({ e_interac: typeInfo });
  }, [typeInfo]);
  useEffect(() => {
    setTypeInfo(pos.e_interac);
  }, [isEdit]);
  return (
    <>
      <div className="input__outer">
        <label htmlFor="">
          Renter to confirm recipient’s e-interac cell or email address:
        </label>
        <div className="formm__double">
          {/* <div className="input__outer">
            <div
              className={
                "input " +
                (error &&
                pos.e_interac.cell === "" &&
                pos.e_interac.email === ""
                  ? "error"
                  : "")
              }
            >
              <input
                value={typeInfo.cell}
                onChange={onChangeInput("cell")}
                type="text"
                placeholder="XXX-XXXXXXX"
              />
              {error &&
                pos.e_interac.cell === "" &&
                pos.e_interac.email === "" && (
                  <span className="errorM">This field required</span>
                )}
            </div>
          </div> */}
          <div className="input__outer">
            <div
              className={
                "input " +
                (error &&
                pos.e_interac.cell === "" &&
                pos.e_interac.email === ""
                  ? "error"
                  : "")
              }
            >
              <input
                value={typeInfo.email}
                onChange={onChangeInput("email")}
                type="email"
                placeholder="Email / Phone Number"
              />
              {error &&
                pos.e_interac.cell === "" &&
                pos.e_interac.email === "" && (
                  <span className="errorM">This field required</span>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
