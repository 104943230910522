import axios from "axios";
import api from "../config/api";
import { toast } from "react-toastify";

export const addCard = async (data) => {
  let request = await axios({
    method: "post",
    url: `${api.payment.card.addCard}`,
    headers: {
      // Authorization: `Bearer ${api.token}`,
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data,
  }).catch((err) => new Error(err));
  return request;
};

export const getCards = async () => {
  let request = await axios({
    method: "get",
    url: `${api.payment.card.getCard}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).catch((err) => new Error(err));
  return request;
};

export const deleteCard = async ({ cardId }) => {
  let request = await axios({
    method: "delete",
    url: `${api.payment.card.deleteCard}`.replace("{{cardId}}", cardId),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).catch((err) => new Error(err));
  return request;
};

export const addBankAccount = async (data) => {
  let request = await axios({
    method: "post",
    url: `${api.payment.bankAccount.addBankAccount}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data,
  }).catch((err) => new Error(err));
  return request;
};

export const getBankAccount = async () => {
  let request = await axios({
    method: "get",
    url: `${api.payment.bankAccount.getBankAccount}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).catch((err) => new Error(err));
  return request;
};

export const deleteBankAccount = async ({ bank_id }) => {
  let request = await axios({
    method: "delete",
    url: `${api.payment.bankAccount.deleteBankAccount}`.repeat(
      "{{bankId}}",
      bank_id
    ),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).catch((err) => new Error(err));
  return request;
};

export const addTransaction = async (data) => {
  let request = await axios({
    method: "post",
    url: `${api.payment.transaction.addTransaction}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data,
  }).catch((err) => {
    // console.log("err", err.response.data.message);
    // const errDet = JSON.parse(err.response.data.message);
    toast.error(err.response.data.message);
    new Error(err);
  });
  console.log("request", request);
  return request;
};

export const getAllTransactions = async () => {
  let request = await axios({
    method: "get",
    url: `${api.payment.transaction.getAllTransaction}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).catch((err) => new Error(err));
  return request;
};
export const getTransactionFee = async ({ transactionType,amount }) => {
  let request = await axios({
    method: "get",
    url: `${api.payment.transaction.transaction_fee}`.replace("{{transactionType}}", transactionType).replace("{{amount}}", amount),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).catch((err) => new Error(err));
  return request;
};
