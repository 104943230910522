import { cardIcon, maserIcon } from "../Base/SVG";

export const transactions = [
  {
    id: "1",
    amount: "$13,50.00",
    date: "01/01/2023",
    delivery: "03/01/2023",
    reference: "ABX001",
    confirmNo: "AA000000111",
    memo: "Reason A Paying For Goods And Services",
    status: "success",
  },

  {
    id: "2",
    amount: "$13,50.00",
    delivery: "03/01/2023",
    reference: "ABX001",
    confirmNo: "AA000000111",
    date: "01/01/2023",
    memo: "Reason A Paying For Goods And Services",
    status: "pending",
  },
  {
    id: "3",
    amount: "$13,50.00",
    delivery: "03/01/2023",
    reference: "ABX001",
    confirmNo: "AA000000111",
    date: "01/01/2023",
    memo: "Reason A Paying For Goods And Services",
    status: "success",
  },
  {
    id: "4",
    amount: "$13,50.00",
    date: "01/01/2023",
    delivery: "03/01/2023",
    reference: "ABX001",
    confirmNo: "AA000000111",
    memo: "Reason A Paying For Goods And Services",
    status: "success",
  },
  {
    id: "5",
    amount: "$13,50.00",
    delivery: "03/01/2023",
    reference: "ABX001",
    confirmNo: "AA000000111",
    date: "01/01/2023",
    memo: "Reason A Paying For Goods And Services",
    status: "pending",
  },
  {
    id: "6",
    amount: "$13,50.00",
    delivery: "03/01/2023",
    reference: "ABX001",
    confirmNo: "AA000000111",
    date: "01/01/2023",
    memo: "Reason A Paying For Goods And Services",
    status: "success",
  },
  {
    id: "7",
    amount: "$13,50.00",
    delivery: "03/01/2023",
    reference: "ABX001",
    confirmNo: "AA000000111",
    date: "01/01/2023",
    memo: "Reason A Paying For Goods And Services",
    status: "pending",
  },
  {
    id: "8",
    amount: "$13,50.00",
    delivery: "03/01/2023",
    reference: "ABX001",
    confirmNo: "AA000000111",
    date: "01/01/2023",
    memo: "Reason A Paying For Goods And Services",
    status: "success",
  },
  {
    id: "9",
    amount: "$13,50.00",
    delivery: "03/01/2023",
    reference: "ABX001",
    confirmNo: "AA000000111",
    date: "01/01/2023",
    memo: "Reason A Paying For Goods And Services",
    status: "success",
  },
  {
    id: "10",
    amount: "$13,50.00",
    delivery: "03/01/2023",
    reference: "ABX001",
    confirmNo: "AA000000111",
    date: "01/01/2023",
    memo: "Reason A Paying For Goods And Services",
    status: "success",
  },
];
export const cards = [
  {
    id: "1",
    type: "Master Card",
    name: "Karol Buratynski",
    date: "05/22",
    cardNumber: "9873 9873 9873 9873",
    cardIcon: cardIcon,
    typeIcon: maserIcon,
  },
  {
    id: "2",
    type: "Master Card",
    name: "Karol Buratynski",
    date: "05/22",
    cardIcon: cardIcon,
    cardNumber: "9873 9873 9873 9873",
    typeIcon: maserIcon,
  },
  {
    id: "3",
    type: "Master Card",
    name: "Tosin Lorika",
    date: "12/12",
    cardIcon: cardIcon,
    cardNumber: "1234 4312 2342 2342",
    typeIcon: maserIcon,
  },
];
export const inboxes = [
  {
    id: "1",
    date: "Today",
    subject: "Welcome to PayQue",
    text: "Welcome to Payue Solution! We are glad to have you onboard to build or repair your credit by utilizing your rental payments. \n This inbox is where you will receive important updates about your account. We recommend that you frequently visit. Enjoy the platform!",
  },
];
 const payees = [
  {
    id: "1",
    avatar: "/images/avatars/avatar-1.png",
    company_name: "ABC Company",
    payment_type: "Cheque",
  },
  {
    id: "2",
    avatar: "/images/avatars/avatar-2.png",
    company_name: "EFG Company",
    payment_type: "e-interac",
  },
  {
    id: "3",
    avatar: "/images/avatars/avatar-3.png",
    company_name: "ABC Company",
    payment_type: "e-interac",
  }, {
    id: "4",
    avatar: "/images/avatars/avatar-4.png",
    company_name: "John Smith",
    payment_type: "ACH Bank Transfer (Wire)",
  },
];
