import React from "react";
import { Route, Routes } from "react-router-dom";
import SignUp from "./SignUp/SignUp";
import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import OtpInputScreen from "./SignUp/OtpEnder";

export default function Auth({ setIsAuth }) {
  return (
    <>
      <Routes>
        <Route index element={<SignIn setIsAuth={setIsAuth} />} />
        <Route path="/sign-up" element={<SignUp setIsAuth={setIsAuth} />} />
        <Route path="/forgot-password" element={<ForgotPassword setIsAuth={setIsAuth}/>}/>
        <Route path="/reset-password" element={<ResetPassword setIsAuth={setIsAuth}/>}/>
        <Route path="/otp" element={<OtpInputScreen setIsAuth={setIsAuth} />} />
      </Routes>
    </>
  );
}
