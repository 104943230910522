import React, { useState } from "react";

import DatePicker from "react-datepicker";

export default function DateInput({ placeholder, pos, updatePos, type }) {
  const onChange = (date) => {
    updatePos({ [type]: date });
    setFocus(false);
  };
  const [focus, setFocus] = useState(false);

  return (
    <div className={"input inputDate " + (focus ? "active" : "")}>
      <DatePicker
        selected={pos?.[type]}
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        startDate={pos?.[type]}
        placeholderText={placeholder}
      />
    </div>
  );
}
