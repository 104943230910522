import React ,{useEffect, useState} from "react";
import Header from "../../../Base/Header";
import Step1 from "./Step1";
import Step2 from "./Step2";

export default function Main({
  setSteps,
  steps,
  form,
  updatePos,
  pos,
  onChangeInput,
  setPayNow,
  updateForm,
  error,
  profile,
  setError,
  exportRef,
}) {

  const [completedTitle, setCompletedTitle] = useState("Cheque Completed");
  const [completedText, setCompletedText] = useState("Your cheque request has been successfully processed and will be on its way.");

useEffect(()=>{
  if(pos.payment_type.value  === "e-interac"){
    setCompletedTitle("E-interac Completed")
    setCompletedText("Your e-interac request has been successfully processed and will be on its way.")
  }
  else if(pos.payment_type.value  === "Cheque Delivery"){
    setCompletedTitle("Cheque Completed")
    setCompletedText("Your cheque request has been successfully processed and will be on its way.")
  }
  else if(pos.payment_type.value  === "ACH Bank Transfer (Wire)"){
    setCompletedTitle("ACH Wire Transfer Completed")
    setCompletedText("Your ACH wire transfer request has been successfully processed and will be on its way.")
  }
},[pos.payment_type.value])
  
  return (
    <div className="contractMain">
      {steps === "step1" && (
        <Header title="Make a Payment" text="Make a payment to your payee" form={profile}/>
      )}
      {steps === "step2" && (
        <Header
          title={completedTitle}
          text={completedText}
          form={profile}
        />
      )}
      {steps === "step1" && (
        <Step1
          error={error}
          setError={setError}
          setPayNow={setPayNow}
          updateForm={updateForm}
          setSteps={setSteps}
          form={form}
          updatePos={updatePos}
          pos={pos}
          onChangeInput={onChangeInput}
        />
      )}
      {steps === "step2" && <Step2 pos={pos} exportRef={exportRef} />}
    </div>
  );
}
