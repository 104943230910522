import React, { useState } from "react";
import { cardIcon, closeIcon, qrIcon } from "../../../Base/SVG";
import { motion } from "framer-motion";
import MaskedInput from "react-text-mask";
import {
  AMERICANEXPRESS,
  OTHERCARDS,
  EXPIRYDATE,
  CVC,
  CARDARR,
  CARDICON,
} from "../../../Base/constants";
import {
  minLength,
  stripeCardExpirValidation,
  stripeCardNumberValidation,
  textWithSpacesOnly,
} from "../../../Base/validation";
import { addCard } from "../../../service/payment";
import { toast } from "react-toastify";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const ParentComponent = ({ form, setCreate, updateForm, create }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  return (
    <Elements stripe={stripePromise}>
      <Create
        create={create}
        form={form}
        setCreate={setCreate}
        updateForm={updateForm}
      />
    </Elements>
  );
};

function Create({ form, setCreate, updateForm, create }) {
  const [pos, setPos] = useState({
    id: "",
    name: "",
    date: "",
    cardNumber: "",
    cvv: "",
    save: true,
    cardType: "",
    cardImage: "",
  });
  const updatePos = (data) => {
    setPos((pos) => ({ ...pos, ...data }));
  };
  const onChangeInput = (input) => (e) => {
    setPos((pos) => ({ ...pos, [input]: e.target.value }));
  };

  const onClose = (e) => {
    if (e.target === e.currentTarget) setCreate(null);
  };

  function findDebitCardType(cardNumber) {
    const regexPattern = {
      MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
      VISA: /^4[0-9]{2,}$/,
      AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
      DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
      DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
      JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/,
    };
    for (const card in regexPattern) {
      if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card]))
        return card;
    }
    return "";
  }
  const [error, setError] = useState({});
  const [cardType, setCardType] = useState();

  const handleValidations = (type, value) => {
    let errorText;
    switch (type) {
      case "card":
        let cardInfo = findDebitCardType(value);
        setCardType(findDebitCardType(value));
        updatePos({ cardImage: CARDICON[cardInfo], cardType: cardInfo });
        errorText = stripeCardNumberValidation(value);
        setError({ ...error, cardNumberError: errorText });
        break;
      case "cardHodler":
        errorText = value === "" ? "Required" : textWithSpacesOnly(value);
        setError({ ...error, nameError: errorText });
        break;
      case "expiry":
        errorText =
          value === "" ? "Required" : stripeCardExpirValidation(value);
        setError({ ...error, dateError: errorText });
        break;
      case "securityCode":
        errorText = value === "" ? "Required" : minLength(3)(value);
        setError({ ...error, cvvError: errorText });
        break;
      default:
        break;
    }
  };

  const handleBlur = (e) => {
    handleValidations(e.target.name, e.target.value);
  };
  const checkErrorBeforeSave = () => {
    let errorValue = {};
    let isError = false;
    Object.keys(pos).forEach(async (val) => {
      if (
        pos?.[val] === "" &&
        val !== "id" &&
        val !== "cardImage" &&
        val !== "cardType"
      ) {
        errorValue = { ...errorValue, [`${val + "Error"}`]: "Required" };
        isError = true;
      }
    });
    setError(errorValue);
    return isError;
  };
  const stripe = useStripe();
  const elements = useElements();

  const handleSave = async () => {
    let errorCheck = checkErrorBeforeSave();
    if (errorCheck) {
      try {
        const card = elements.getElement(CardElement);
        console.log("Card Created: ", card);
        const stripeResponse = await stripe.createToken(card,{name:pos.name});
        console.log("Stripe token :", stripeResponse);
        const payload = {
          card_name:pos.name,
          card_token_id: stripeResponse.token.id,
        };
        let { data } = await addCard(payload);
        if (data?.success) {
          toast.success("Card saved successfully");
          updateForm({
            cards: [
              ...form.cards,
              {
                ...pos,
                id: payload.card_token_id,
                cardNumber: payload.number,
              },
            ],
          });
          setCreate(null);
        } else {
          toast.error("Add Card Error");
          console.log("error in saving card", data);
        }
      } catch (error) {
        toast.error("Stripe Error");
        console.log("error in saving card", error);
      }
    }
  };

  return (
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
        exit={{ opacity: 0 }}
        className={
          "walletSide__create " + (create === "create" ? "active" : "")
        }
        onClick={onClose}
      >
        <div className="walletSide__create-inner">
          <h4>
            Add New Credit Card{" "}
            <button onClick={onClose} type="button">
              {closeIcon}
            </button>
          </h4>
          <div className="card big">
            <div className="card__bg">
              <img
                src={process.env.PUBLIC_URL + "/images/place.png"}
                alt="place"
              />
            </div>
            <div className="card__inner">
              <div className="card__head">
                <div className="card__head-icon">{cardIcon}</div>
                <div className="card__head-type">
                  {(!error || !error.cardError) &&
                    CARDARR.includes(cardType) && (
                      <img src={CARDICON[cardType]} alt="card" />
                    )}
                  <span>{cardType}</span>
                </div>
              </div>
              <div className="card__body">
                <h6>{pos.name === "" ? "Card Holder's Name" : pos.name}</h6>
                <p className="sm">
                  **** **** ****{" "}
                  {pos.cardNumber === "" ? "0000" : pos.cardNumber.slice(-4)}
                </p>
                <p className="xsm">{pos.date === "" ? "00/00" : pos.date}</p>
              </div>
            </div>
          </div>
          <button type="button" className="qr">
            {qrIcon} Scan your card
          </button>
          <div className="walletInput__outer">
            <label htmlFor="">Cardholder name</label>

            <div className="walletInput">
              <input
                type="text"
                name="cardHodler"
                required
                placeholder="CardHolder's Name"
                value={pos.name}
                onChange={onChangeInput("name")}
                onBlur={handleBlur}
              />
              {error && error.nameError && error.nameError.length > 1 && (
                <span>{error.nameError}</span>
              )}
            </div>
          </div>
          <div className="walletInput__outer">
            <label htmlFor="">Card number</label>
            <div className="walletInput">
              {/* <MaskedInput
                mask={
                  ["37", "34"].includes(
                    pos.cardNumber &&
                      pos.cardNumber.split("").splice(0, 2).join("")
                  )
                    ? AMERICANEXPRESS
                    : OTHERCARDS
                }
                guide={false}
                placeholderChar={"\u2000"}
                placeholder="Card Number"
                name="card"
                required
                value={pos.cardNumber}
                onChange={onChangeInput("cardNumber")}
                onBlur={handleBlur}
              /> */}
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              
              />
              {/* {error &&
                error.cardNumberError &&
                error.cardNumberError.length > 1 && (
                  <span>{error.cardNumberError}</span>
                )} */}
            </div>
          </div>
          <div className="walletInput__row">
            {/* <div className="walletInput__outer">
              <label htmlFor="">Valid Through</label>
              <div className="walletInput">
                <MaskedInput
                  mask={EXPIRYDATE}
                  guide={false}
                  name="expiry"
                  required
                  placeholderChar={"\u2000"}
                  placeholder="Expiry Date (MM/YY)"
                  value={pos.date}
                  onChange={onChangeInput("date")}
                  onBlur={handleBlur}
                />
                {error && error.dateError && error.dateError.length > 1 && (
                  <span>{error.dateError}</span>
                )}
              </div>
            </div> */}
            {/* <div className="walletInput__outer">
              <label htmlFor="">CVV</label>
              <div className="walletInput">
                <MaskedInput
                  mask={CVC}
                  guide={false}
                  name="securityCode"
                  required
                  placeholderChar={"\u2000"}
                  placeholder="CVV"
                  value={pos.cvv}
                  onChange={onChangeInput("cvv")}
                  onBlur={handleBlur}
                />
                {error && error.cvvError && error.cvvError.length > 1 && (
                  <span>{error.cvvError}</span>
                )}
              </div>
            </div> */}
          </div>
          <div className="check">
            <input
              type="checkbox"
              checked={pos.save}
              onChange={(e) => {
                updatePos({ save: e.target.checked });
              }}
            />
            <label htmlFor="">Save Your Card Details</label>
          </div>
          <button type="button" onClick={handleSave} className="button green">
            Add
          </button>
        </div>
      </motion.div>
    </div>
  );
}

export default ParentComponent;
