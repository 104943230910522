import React from "react";
import Header from "../Base/Header";
import { searchIcon } from "../Base/SVG";
import Select from "./components/Select";
import Table from "./components/Table";

const filters = [
  {
    id: "1",
    value: "Sort by date",
  },
  {
    id: "2",
    value: "Sort by price",
  },
  {
    id: "3",
    value: "Sort by memo",
  },
  {
    id: "4",
    value: "Sort by no",
  },
];
const types = [
  {
    id: "1",
    value: "Export",
  },
  {
    id: "2",
    value: "Import",
  },
  {
    id: "3",
    value: "Download",
  },
];
export default function Transaction({form}) {
  return (
    <>
      <div className="transaction">
        <div className="auto__container">
          <Header title="History" text="Search for transactions" form={form} />
          <div className="transaction__inner">
            <div className="transaction__head">
              <div className="transactionSearch">
                <input type="text" placeholder="Search" />
                <button type="button">{searchIcon}</button>
              </div>
              <div className="transaction__head-selects">
                <Select list={filters} selected={filters[0]} />
                <Select list={types} selected={types[0]} />
              </div>
            </div>
            <Table />
          </div>
        </div>
      </div>
    </>
  );
}
