import React, { useEffect, useState } from "react";
import Type1 from "./Type1";
import Type3 from "./Type3";
import Type2 from "./Type2";
import CustomSelect from "./CustomSelect";
import DateInput from "./DateInput";
import TypeSelect from "./TypeSelect";
import { formatNumber } from "../../../../../Base/globalFunc";

const categories = [
  {
    id: "1",
    value: "Rental",
  },
  {
    id: "2",
    value: "Mortgage",
  },
  {
    id: "3",
    value: "Others",
  },
];
const subcategories = [
  {
    id: "1",
    value: "Residential Unit Rent",
  },
  {
    id: "2",
    value: "Commercial Unit Rent",
  },
];
const periods = [
  {
    id: "1",
    value: "days",
  },
  {
    id: "2",
    value: "months",
  },
  {
    id: "3",
    value: "years",
  },
];
const contractTypeList = [
  {
    id: "1",
    type: "paper_check",
    hint: "(Standard Delivery is FREE * Expedited Delivery Available)",
    expect: "Expect in 2-11 Days",
  },
  {
    id: "2",
    type: "e-interac",
    hint: "(available within 24 hrs)",
    expect: "Expect in 2-3 Days",
  },
  {
    id: "3",
    type: "ACH Bank Transfer (Wire)",
    hint: "",
    expect: "Expect in 4-5 Days",
  },
];
export default function Info({
  pos,
  onChangeInput,
  updatePos,
  payeeHandler,
  isEdit,
  error,
}) {
  const [type, setType] = useState("type1");
  const changeType = (item) => {
    if (item.type === "paper_check") {
      setType("type1");
      updatePos({
        e_interac: {
          cell: "",
          email: "",
        },
        wire: {
          companyName: "",
          firstName: "",
          branchNumber: "",
          accountNumber: "",
        },
      });
    }
    if (item.type === "e-interac") {
      setType("type2");
      updatePos({
        paper_check: {
          answer: "",
          address: "",
          province: "Alberta",
          city: "",
          country: "",
          zip: "",
        },
        wire: {
          companyName: "",
          firstName: "",
          branchNumber: "",
          accountNumber: "",
        },
      });
    }
    if (item.type === "ACH Bank Transfer (Wire)") {
      setType("type3");
      updatePos({
        paper_check: {
          answer: "",
          address: "",
          province: "Alberta",
          city: "",
          country: "",
          zip: "",
        },
        e_interac: {
          cell: "",
          email: "",
        },
      });
    }
    updatePos({ payment_type: item });
  };
  const contractTypeIndex = React.useMemo(() => {
    if (pos.payment_type.type !== "") {
      return contractTypeList.findIndex(
        (item) => item.type === pos.payment_type.type
      );
    }
    return null;
    // eslint-disable-next-line
  }, [pos]);
  const periodsIndex = React.useMemo(() => {
    if (pos.contract_period.value !== "") {
      return periods.findIndex(
        (item) => item.value === pos.contract_period.value
      );
    }
    return null;
    // eslint-disable-next-line
  }, [pos]);
  const categoryIndex = React.useMemo(() => {
    if (pos.category.value !== "") {
      return categories.findIndex((item) => item.value === pos.category.value);
    }
    return null;
    // eslint-disable-next-line
  }, [pos]);
  const subcategoryIndex = React.useMemo(() => {
    if (pos.subCategory.value !== "") {
      return subcategories.findIndex(
        (item) => item.value === pos.subCategory.value
      );
    }
    return null;
    // eslint-disable-next-line
  }, [pos]);
  const changeMedium = (key) => (item) => updatePos({ [key]: item });

  useEffect(() => {
    if (pos.payment_type.type === "paper_check") {
      setType("type1");
    }
    if (pos.payment_type.type === "e-interac") {
      setType("type2");
    }
    if (pos.payment_type.type === "ACH Bank Transfer (Wire)") {
      setType("type3");
    }
  }, [pos]);

  return (
    <div className="formm">
      <div className="formm__inner">
        <div className="input__outer">
          <label htmlFor="">
            Who you are paying for? (Individual, Company)
          </label>
          <div
            className={
              "input " + (error && pos.paying_for === "" ? "error" : "")
            }
          >
            <input
              type="text"
              value={pos.paying_for}
              onChange={onChangeInput("paying_for")}
              placeholder="e.g John Smith / abc corp"
            />
            {error && pos.paying_for === "" && (
              <span className="errorM">This field required</span>
            )}
          </div>
        </div>
        <div className="input__outer">
          <label htmlFor="">Phone Number</label>
          <div
            className={
              "input " +
              (error && pos.phone_number === "" && pos.email === ""
                ? "error"
                : "")
            }
          >
            <input
              value={pos.phone_number}
              onChange={(e) =>
                updatePos({ phone_number: formatNumber(e.target.value) })
              }
              type="text"
              placeholder="XXX-XXX-XXXX"
            />
            {error && pos.phone_number === "" && pos.email === "" && (
              <span className="errorM">This field required</span>
            )}
          </div>
        </div>
        <div className="input__outer">
          <label htmlFor="">Email Address</label>
          <div
            className={
              "input " +
              ((error && pos.phone_number === "" && pos.email === "") ||
                (error &&
                  pos.email !== "" &&
                  /^[\w-\.]+@([\w-]+\.)+[\w-]{2,15}$/.test(pos.email) === false)
                ? "error"
                : "")
            }
          >
            <input
              value={pos.email}
              onChange={onChangeInput("email")}
              type="email"
              placeholder="john.smith@internet.com"
            />
            {error && pos.phone_number === "" && pos.email === "" ? (
              <span className="errorM">This field required</span>
            ) : error &&
              pos.email !== "" &&
              /^[\w-\.]+@([\w-]+\.)+[\w-]{2,15}$/.test(pos.email) === false ? (
              <span className="errorM">Invalid Email address</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="formm__double">
          <div className="input__row w40">
            <label htmlFor="">Length of Contract</label>
            <div className="input__outer">
              <div className="input">
                <input
                  value={pos.contract_number}
                  onChange={onChangeInput("contract_number")}
                  type="number"
                  placeholder="0"
                />
              </div>
            </div>
            <div className="input__outer">
              <CustomSelect
                onChange={changeMedium("contract_period")}
                list={periods}
                selected={periods[periodsIndex]}
              />
            </div>
          </div>
          <div className="input__row w60">
            <div className="input__outer">
              <label htmlFor="">Start date</label>
              <DateInput
                updatePos={updatePos}
                pos={pos}
                type="start_date"
                placeholder="Start date"
              />
            </div>
            <div className="input__outer">
              <label htmlFor="">End date</label>
              <DateInput
                placeholder="End date"
                updatePos={updatePos}
                pos={pos}
                type="end_date"
              />
            </div>
          </div>
        </div>
        <div className="formm__double">
          <div className="input__outer">
            <label htmlFor="">Category</label>
            <CustomSelect
              onChange={changeMedium("category")}
              list={categories}
              selected={categories[categoryIndex]}
            />
          </div>
          <div className="input__outer">
            <label htmlFor="">SubCategory</label>
            <CustomSelect
              onChange={changeMedium("subCategory")}
              list={subcategories}
              selected={subcategories[subcategoryIndex]}
            />
          </div>
        </div>
        <div className="input__outer">
          <label htmlFor="">
            How do you want the recipient to receive your payment:”
          </label>
          <TypeSelect
            onChange={changeType}
            list={contractTypeList}
            selected={contractTypeList[contractTypeIndex]}
          />
        </div>
        {type === "type1" && (
          <Type1 error={error} pos={pos} isEdit={isEdit} updatePos={updatePos} />
        )}
        {type === "type2" && (
          <Type2 error={error} pos={pos} isEdit={isEdit} updatePos={updatePos} />
        )}
        {type === "type3" && (
          <Type3 error={error} pos={pos} isEdit={isEdit} updatePos={updatePos} />
        )}
      </div>

      {/* need to verify */}
      {/* <div className="input__outer">
        <label htmlFor="">
          Address
        </label>
        <div
          className={
            "input " + (error && pos.address === "" ? "error" : "")
          }
        >
          <input
            type="text"
            value={pos.address}
            onChange={onChangeInput("address")}
            placeholder="e.g street 2 / abc corp"
          />
          {error && pos.address === "" && (
            <span className="errorM">This field required</span>
          )}
        </div>
      </div>
      <div className="input__outer">
        <label htmlFor="">
          province
        </label>
        <div
          className={
            "input " + (error && pos.province === "" ? "error" : "")
          }
        >
          <input
            type="text"
            value={pos.province}
            onChange={onChangeInput("province")}
            placeholder="e.g John Smith / abc corp"
          />
          {error && pos.province === "" && (
            <span className="errorM">This field required</span>
          )}
        </div>
      </div>
      <div className="input__outer">
        <label htmlFor="">
          city
        </label>
        <div
          className={
            "input " + (error && pos.city === "" ? "error" : "")
          }
        >
          <input
            type="text"
            value={pos.city}
            onChange={onChangeInput("city")}
            placeholder="e.g John Smith / abc corp"
          />
          {error && pos.city === "" && (
            <span className="errorM">This field required</span>
          )}
        </div>
      </div>
      <div className="input__outer">
        <label htmlFor="">
          country
        </label>
        <div
          className={
            "input " + (error && pos.country === "" ? "error" : "")
          }
        >
          <input
            type="text"
            value={pos.country}
            onChange={onChangeInput("country")}
            placeholder="e.g John Smith / abc corp"
          />
          {error && pos.country === "" && (
            <span className="errorM">This field required</span>
          )}
        </div>
      </div>
      <div className="input__outer">
        <label htmlFor="">
          zip
        </label>
        <div
          className={
            "input " + (error && pos.zip === "" ? "error" : "")
          }
        >
          <input
            type="text"
            value={pos.zip}
            onChange={onChangeInput("zip")}
            placeholder="e.g John Smith / abc corp"
          />
          {error && pos.zip === "" && (
            <span className="errorM">This field required</span>
          )}
        </div>
      </div> */}

      {/* to here  */}

      <div className="formm__footer">
        {type === "type3" && (
          <p>
            Normally, funds are accessible to the recipient within a time-frame
            of 2-7 business days
          </p>
        )}
        <button type="button" className="button green" onClick={payeeHandler}>
          {isEdit ? "Update" : "Add"} Payee
        </button>
      </div>
    </div>
  );
}
