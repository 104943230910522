import React from "react";
import Summary from "./components/Summary/Summary";
import Assets from "./components/Assets/Assets";
import { useState } from "react";
import { minusIcon, plusIcon } from "../../../Base/SVG";
import { motion, AnimatePresence } from "framer-motion";

export default function Side({
  form,
  steps,
  pos,
  setPos,
  updatePos,
  exportAsImage,
  exportRef,
  Print,
}) {
  const [isShown, setIsShown] = useState(true);

  return (
    <div className="contractSide">
      {steps === "step1" && (
        <div
          className={"contractSide__head " + (isShown ? "active" : "")}
          onClick={() => setIsShown(!isShown)}
        >
          <h4>Wallet</h4>
          {isShown ? minusIcon : plusIcon}
        </div>
      )}
      {steps === "step2" && (
        <div
          className={"contractSide__head " + (isShown ? "active" : "")}
          onClick={() => setIsShown(!isShown)}
        >
          <h4>Cheque Assets</h4>
          {isShown ? minusIcon : plusIcon}
        </div>
      )}

      <AnimatePresence>
        {isShown && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, y: 10 }}
            className="contractSide__inner"
          >
            {steps === "step1" && (
              <p>
                You will allow this payment to be made by selecting from one of
                the following registered cards.
              </p>
            )}
            {steps === "step2" &&
            pos.payment_type.value === "Cheque Delivery" ? (
              <div className="assets__head">
                <p>You can choose how to download your cheque here.</p>
              </div>
            ) : null}
            {steps === "step1" && (
              <Summary form={form} pos={pos} setPos={setPos} />
            )}
            {steps === "step2" &&
            pos.payment_type.value === "Cheque Delivery" ? (
              <Assets
                Print={Print}
                exportAsImage={exportAsImage}
                exportRef={exportRef}
                form={form}
                pos={pos}
                updatePos={updatePos}
              />
            ) : null}
          </motion.div>
        )}
        <div>
          <span
            style={{
              color: "#fff",
              fontWeight: "bold",
              backgroundColor: "#5433FF",
              padding: "5px",
              marginTop: "20px",
            }}
          >
            Stripe
          </span>
          <p style={{ color: "#808080", marginTop: "10px" }}>
            Your payment is processed securely through Stripe ensuring your
            transaction is protected with industry-leading security measures.
          </p>
        </div>
      </AnimatePresence>
    </div>
  );
}
