import React, { useRef, useState } from "react";
import Main from "./components/Main/Main";
import Side from "./components/Side/Side";
import html2canvas from "html2canvas";

export default function Payment({
  form,
  payNow,
  updateForm,
  setPayNow,
  profile,
}) {
  const [steps, setSteps] = useState("step1");
  const [error, setError] = useState(false);

  const [pos, setPos] = useState(
    payNow
      ? payNow
      : {
          id: form.payment.length + 1,
          pay: "",
          amount: "",
          amount_words: "",
          total_amount: "",
          // issue_date: "",
          delivery_date: "",
          memo_reason: {
            id: "1",
            value: "Rental",
          },
          memo_text: "",
          IsRecurringPayment: false,
          RecurringStopDate: "",
          description: "",
          fees: 0,
          delivery_cost: 0,
          payment_type: {
            id: "1",
            value: "Cheque Delivery",
          },
          delivery_check: "",
          full_name: "",
          payee_id: "",
          card_id: "",
          card_name: "",
          phone_number: "",
          email: "",
          terms: true,
          paper_check: {
            address: "",
            province: "Alberta",
            city: "",
            country: "Canada",
            zip: "",
          },
          e_interac: {
            cell: "",
            email: "",
          },
          ach_bank_transfer: {
            companyName: "",
            firstName: "",
            branchNumber: "",
            accountNumber: "",
          },
          transaction_id: "",
        }
  );
  const updatePos = (data) => {
    setPos((pos) => ({ ...pos, ...data }));
  };
  const onChangeInput = (input) => (e) => {
    setPos((pos) => ({ ...pos, [input]: e.target.value }));
  };

  const exportAsImage = async (el, imageFileName) => {
    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, imageFileName);
  };
  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };
  const exportRef = useRef();
  const Print = () => {
    let popupWinindow;
    let innerContents = document.getElementById("printablediv").innerHTML;
    popupWinindow = window.open(
      "",
      "_blank",
      "width=800px,height=600px,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
    );
    popupWinindow.document.open();
    popupWinindow.document.write(`
    <!DOCTYPE html>
    <html>
      <head>
        <link rel="stylesheet" type="text/css" href="./css/main.css" />
        <title>File Name</title>
        <script>
          window.onload = function() {
            setTimeout(() => {
              window.print();
            }, 1000);      
          };
        </script>
      </head>
      <body>
        ${innerContents}
      </body>
    </html>
    `);
    popupWinindow.document.close();
  };
  return (
    <div className="contract">
      <div className="auto__container">
        <div className="contract__inner">
          <Main
            exportRef={exportRef}
            error={error}
            setError={setError}
            setPayNow={setPayNow}
            form={form}
            onChangeInput={onChangeInput}
            updatePos={updatePos}
            pos={pos}
            steps={steps}
            profile={profile}
            setSteps={setSteps}
            updateForm={updateForm}
          />
          <Side
            exportAsImage={exportAsImage}
            exportRef={exportRef}
            pos={pos}
            setPos={setPos}
            updatePos={updatePos}
            form={form}
            Print={Print}
            steps={steps}
          />
        </div>
      </div>
    </div>
  );
}
