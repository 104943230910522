import React from "react";

export default function Main({ inbox }) {
  return (
    <div className="inboxMain">
      <div className="inboxMain__inner">
        <div className="inboxMain__inner-text">
          <h6>Subject: {inbox.subject}</h6>
          <p >{inbox.text}</p>
        </div>
      </div>
    </div>
  );
}
