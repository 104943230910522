import React, { useEffect, useState } from "react";
import History from "../../../Components/History";
import Header from "../../../Base/Header";
import { cardIcon, maserIcon, plusIcon } from "../../../Base/SVG";
import Slider from "react-slick";
import {
  CARDARR,
  CARDICON,
} from "../../../Base/constants";
import { getCards } from "../../../service/payment";
export default function Main({
  form,
  setCreate,
  setEditCard,
  editCard,
  updateForm,
  profile
}) {
  const [error, setError] = useState({});
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <div className="walletMain">
      <Header title="Wallet" text="Manage your wallet" form={profile}/>
      <div className="walletMain__cards">
        <div className="walletMain__cards-head">
          <h4 className="big">My Card</h4>
          <button
            type="button"
            className="add"
            onClick={() => setCreate("create")}
          >
            {plusIcon}
            Add new card
          </button>
        </div>
        <div className="walletMain__cards-slider">
          <Slider {...settings}>
            {form.cards.map((item, index) => {
              return (
                <div
                  className={"card big " + (editCard === item ? "active" : "")}
                  key={index}
                  onClick={() => {
                    setCreate("edit");
                    setEditCard(item);
                  }}
                >
                  <div className="card__bg">
                    <img
                      src={process.env.PUBLIC_URL + "/images/frame.png"}
                      alt="frame"
                    />
                  </div>
                  <div className="card__inner">
                    <div className="card__head">
                      <div className="card__head-icon">{cardIcon}</div>
                      <div className="card__head-type">
                        <img src={item.cardImage} alt="card" />
                        <span>{item.cardType}</span>
                      </div>
                    </div>
                    <div className="card__body">
                      <h6>{item.name}</h6>
                      <p className="sm"> **** **** **** {item.cardNumber}</p>
                      <p className="xsm">{item.date}</p>
                    </div>
                  </div>
                 
                </div>
              );
            })}
            <div
              className="card big "
              onClick={() => {
                setCreate("create");
                setEditCard(null);
              }}
            >
              <div className="card__add">
                <div className="card__add-icon">{plusIcon} </div>
              </div>
              <div className="card__bg">
                <img
                  src={process.env.PUBLIC_URL + "/images/place.png"}
                  alt="place"
                />
              </div>
              <div className="card__inner">
                <div className="card__head">
                  <div className="card__head-icon">{cardIcon}</div>
                  <div className="card__head-type">
                    {maserIcon}
                    <span>Master Card</span>
                  </div>
                </div>
                <div className="card__body">
                  <h6>Card Holder's name</h6>
                  <p className="sm">**** **** ** 3783</p>
                  <p className="xsm">05/22</p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div >
                    <p style={{color:"#808080", marginTop:"30px"}}>
                      Your Card Details are securely encrypted and handled by Stripe ensuring top-level protection for your information <span
                      style={{color:"#fff", fontWeight:"bold",backgroundColor:"#5433FF", padding:"5px"}}
                      >Stripe</span>
                    </p>
                    </div>
      </div>
      
      {/* <History /> */}
    </div>
  );
}
