import React, { useEffect, useMemo, useState } from "react";
import { chevronRight } from "../Base/SVG";
import { transactions } from "../data/data";
import { Pagination } from "./Pagination";
import { Link } from "react-router-dom";
import useMediaQuery from "../Hooks/useMediaQuery";
import { getAllTransactions } from "../service/payment";
import moment from "moment";

export default function History() {
  const [mobTable, setMobTable] = useState(false);

  // const [activeList, setActiveList] = useState(transactions);
  const [activeList, setActiveList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 5;

  useEffect(() => {
    getAllTransactions({}).then(({ data }) => {
      if (data.success && data?.data?.length) {
        data.data = data.data.map(x => {
          return {
            "id": x?.id,
            "amount": x?.total_amount,
            "payee_name": x?.payee_name,
            "date": moment(x?.created_at).format('YYYY-MM-DD'),//date moment
            "delivery": moment(x?.updatedAt || x?.createdAt).format('YYYY-MM-DD'),
            "reference": x?.transaction_id, //reference
            "confirmNo": x?.payee_account_id,
            "user_id": "651918cd69b81a41297ff5cf",
            "card_id": x?.card_id,
            "bank_account_id": "",
            "memo": x?.memo || "No Info Available..!",
            "description": x?.description || "No Info Available..!",
            status: "success",

          }
        })
        setActiveList(data?.data)
      }
    }).catch(err => console.log('error in fetching all transation', err))
  }, [])



  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return activeList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, activeList]);

  const width = useMediaQuery();
  useEffect(() => {
    if (width <= 540) {
      setMobTable(true);
    } else setMobTable(false);
  }, [width]);

  return (
    <div className="history">
      <div className="history__head">
        <h5>Recent Transactions</h5>
        <Link to="/history" className="viewMore">
          View all {chevronRight}
        </Link>
      </div>
      {mobTable ? (
        <div className="historyTable mob">
          <table>
            <thead>
              <tr>
                <th>Issued Date</th>
                <th>Name</th>
                <th>Memo</th>
                {/* <th>Description</th> */}
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <p>{item.date}</p>
                    </td>
                    <td>
                      <p>{item.payee_name}</p>
                    </td>
                    <td>
                      <p>{item.memo}</p>
                    </td>
                    {/* <td>
                      <p>{item.description}</p>
                    </td> */}
                    <td>{item.amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            totalCount={activeList.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      ) : (
        <div className="historyTable">
          <table>
            <thead>
              <tr>
                <th>Amount</th>
                <th>Name</th>
                <th>Issued Date</th>
                <th>Memo</th>
                {/* <th>Description</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>$ {item.amount}</td>
                    <td>{item.payee_name}</td>
                    <td>{item.date}</td>
                    <td>{item.memo}</td>
                    {/* <td>{item.description}</td> */}
                    <td>
                      <span className={"status " + item.status}>
                        {item.status}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            totalCount={activeList.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </div>
  );

}
