import React from "react";

const TermsConditions = ({ closeModal, setChecked }) => {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-content">
          <div className="scrollable-content">
            <h3 style={{ textAlign: "center", marginBottom: 40 }}>
              Terms of Service
            </h3>
            {/* 1.1 */}
            <div className="term-section">
              <h4>2.9 Rent Payment Finality Clause</h4>
              <p style={{ paddingTop: 10 }}>
              When you make any payment through PayQue, it is intended for rent ("Rent Payment") and you agree that this is a final transaction. You will not have the rights to withdraw, dispute, or chargeback this payment using your credit card or any other payment methods.
                <br />
                Non-Refundability: PayQue Solutions Inc has the full right to keep all Rent Payments and will not issue refunds except in specific situations
                <br />
                Exceptional Cases: A refund for a Rent Payment is only possible if both the landlord (receiver) and tenant (payer) agree to it. In such cases, PayQue will still retain any transaction fees from the original payment and may also charge charge additional fees for processing the refund. These fees are non-refundable.
                
              </p>
            </div>
            <div className="term-section">
              <h4>1.1 Agreement</h4>
              <p style={{ paddingTop: 10 }}>
                These Terms of Service are a legal agreement ("Agreement")
                between you ("User", "you", "your", "Payor", or "Biller") and
                PayQue Inc., its subsidiaries ("PayQue", "we", "our", or "us")
                governing your access to and use of payque.com and all
                associated sites ("Website"), the PayQue mobile application
                ("Mobile App"), the Content and Communication (as defined
                below), and any other products or services provided by or
                available through PayQue, including software-as-a-service
                (individually and collectively, the "Services"). You agree that
                by registering for, accessing, or using any of our Services
                (described below), you acknowledge that you have read,
                understood, and agree to be legally bound by this Agreement or
                have the authority to legally bind a third party or entity. You
                further agree that you will use the Services solely as
                contemplated by and in accordance with the terms of this
                Agreement. This Agreement incorporates by reference all
                policies, notices, and other content that appear on our website
                at{" "}
                <a
                  href="https://www.payque.ca"
                  style={{ color: "blue" }}
                  target="new"
                >
                  https://www.payque.ca
                </a>{" "}
                including our Privacy Policy.
              </p>
              <p style={{ marginTop: 10 }}>
                PayQue offers its Services to Users in Canada.
                <br />
                PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING ANY SERVICES.
                THESE TERMS OF SERVICE ARE SUBJECT TO BINDING ARBITRATION AND
                WAIVER OF CLASS ACTION RIGHTS AS DETAILED IN THE ARBITRATION
                SECTION BELOW.
              </p>
            </div>
            {/* 1.2 */}
            <div className="term-section">
              <h4>1.2 PayQue</h4>
              <p style={{ paddingTop: 10 }}>
                PayQue's operations utilize a number of different entities and
                partners to process your request.
                <br />
                If you reside in Canada, this is a legal Agreement between you
                and PayQue Canada Inc., a PayQue Inc. subsidiary. For Canadian
                users, "PayQue," "we," and "us" refer to both PayQue Canada
                Inc. and PayQue Inc.
                <br />
                For all transactions, PayQue utilizes relationships established
                with partners such as Stripe, Moneris, and Royal Bank of Canada
                to process your transactions. These partners operate directly
                or through their authorized affiliates and contractors.
                <br />
                PayQue is an independent, third-party service that is not
                formally affiliated with any party, except where otherwise
                indicated. You acknowledge and agree that your use of the
                Services does not in any way constitute a tri-party agreement
                between you, PayQue, and your payment recipients.
              </p>
            </div>
            {/* 1.3 */}
            <div className="term-section">
              <h4>1.3 Services</h4>
              <p style={{ paddingTop: 10 }}>
                PayQue's services include a platform and additional online
                payment application products and features that enable you to
                make payments to third parties ('Pay') <br />
                You may access and use certain of the services by paying
                applicable fees for each transaction or service utilized, as
                described in PayQue's fee schedule provided. The fees for using
                specific services will be clearly outlined in the fee schedule
                and may vary depending on the nature of the transaction or
                service provided, including subscription and per-transactional
                fees ('Fees'). Subscription-based payment options may also be
                available
                <br />
                The Order and this Agreement set forth the basic terms and
                conditions under which the Services will be delivered. This
                Agreement will govern your access to and use of the Services,
                including the payment of applicable Fees for each transaction or
                service utilized. Your use of the Services is contingent upon
                your payment of the Fees associated with each transaction or
                service in accordance with the fee schedule provided.
              </p>
            </div>
            {/* 1.6 */}
            <div className="term-section">
              <h4>1.6 Payment and Fees</h4>
              <p style={{ paddingTop: 10 }}>
                When you sign up with PayQue, as part of the registration
                process for any paid service, you will need to provide payment
                card information for the applicable charges and fees. You agree
                to pay all charges associated with your use of the Services, as
                well as any additional applicable fees in connection with your
                use of the Services. These charges and fees are non-refundable
                except as otherwise set forth herein. You are responsible for
                keeping all account information accurate and up-to-date,
                including payment card, address, and account contact
                information. You hereby represent that you have the right to
                provide us with your payment card information and authorize
                PayQue to charge the payment card for the applicable charges
                and fees. You hereby authorize PayQue to automatically charge
                your payment card for the services used in accordance with the
                applicable order. If your payment card is declined for any
                payment, we may suspend the Services immediately until your
                payment is brought current.
              </p>
            </div>
            {/* 1.7 */}
            <div className="term-section">
              <h4>1.7 Term and Termination</h4>
              <p style={{ paddingTop: 10 }}>
                Your Order will state the term for the Services ordered. If not
                otherwise specified on the applicable Order, each term will
                continue until the completion of the transactions and charges,
                unless either party terminates pursuant to this Agreement. You
                agree that any applicable fees or charges will not be refunded
                in the event of termination.
                <br />
                Upon expiration or termination of the Services, you will not be
                entitled to a refund of any pre-paid fees or charges. Please
                note that when you become a member of PayQue, you may choose
                to discontinue using certain services, and the fees or charges
                associated with those services will not be refunded. In such
                cases, your account will be deactivated, and you will no longer
                have access to the information provided during the use of the
                Services.
              </p>
            </div>
            {/* 1.8 */}
            <div className="term-section">
              <h4>1.8 Fund Ownership</h4>
              <p style={{ paddingTop: 10 }}>
                PayQue does not take legal ownership of the principal funds
                initiated either under the Pay Service or Accept Service that
                are processed through Stripe, Moneris, Royal Bank of Canada,
                and other banks in Canada. Funds held in these accounts are
                held in the respective banks' names. The accounts reflect that
                they are for the benefit of PayQue's customers. PayQue has the
                right to instruct these banks on the use of funds in the
                accounts, including receiving and forwarding funds to you or
                making payments based on your instructions.
                <br />
                These banks act only as service providers to PayQue, and their
                roles in the completion of a payment are restricted to treasury
                disbursement. You are not a customer of these banks. PayQue
                provides card processing services to you and dispatches payment
                instructions to these banks for the purpose of accomplishing
                these payments.
              </p>
            </div>
            {/* 1.9 */}
            <div className="term-section">
              <h4>1.9 Changes</h4>
              <p style={{ paddingTop: 10 }}>
                PayQue may modify the Services, this Agreement, and/or our
                Privacy Policy from time to time. If we make material changes,
                we will provide you notice through communication via our
                Services, your account, email, or by other means as determined
                by PayQue, giving you the opportunity to review the changes
                before they become effective. If you object to any changes, you
                may close your account and/or terminate the applicable terms.
                Your continued use of our Services after we publish or send
                notice about changes to the Services, this Agreement, or to the
                Privacy Policy means that you are consenting to the updated
                terms.
              </p>
            </div>
            <h3 style={{ marginBottom: 30 }}>Your PayQue Account</h3>
            {/* 2.1 */}
            <div className="term-section">
              <h4>2.1 Eligibility</h4>
              <p style={{ paddingTop: 10 }}>
                In opening an account with PayQue, you represent and warrant
                that: (i) you are at least 18 years of age; (ii) have a valid
                and active email address; (iii) have a valid payment card
                issued on the MasterCard®, Visa®, Discover®, JCB®, or Diners
                Club® networks or a valid bank account in your name; (iv) have
                not previously been suspended or removed from the Services; (v)
                will provide complete, accurate, and up-to-date information for
                any and all documentation as requested by PayQue.
                <br />
                Furthermore, if you use the Services on behalf of another,
                whether a natural person or legal non-natural person or entity,
                you represent and warrant that you are duly authorized and
                empowered by and for that person or entity to agree to and
                accept this Agreement, and represent and warrant that you are
                duly authorized and empowered to use the Services on behalf of
                the person or entity.
              </p>
            </div>
            {/* 2.2 */}
            <div className="term-section">
              <h4>2.2 Registration</h4>
              <p style={{ paddingTop: 10 }}>
                In order to access the Services as a User, you must create an
                account ("Account") with PayQue. To set up your Account, you
                will be required to create log-in credentials and provide
                certain types of personal information, including but not limited
                to the following:
                <br />
                • Your full name and/or entity • Your Home Address • Your email
                address • Your phone number
                <br />
                We may request additional information after or during the
                registration process. To ensure uninterrupted access to the
                Services, you agree to provide true, accurate, and complete
                information promptly. Additionally, you agree to notify us
                promptly if any of your information has changed.
              </p>
            </div>
            {/* 2.4 */}
            <div className="term-section">
              <h4>2.4 Account Security</h4>
              <p style={{ paddingTop: 10 }}>
                Users are asked to create a strong password and provide a phone
                number and email address for a two-factor authentication (2FA)
                authentication factor. You will not provide access to your
                PayQue Account to any outside party and will take reasonable
                precautions to safeguard your password and authentication
                details, keeping them confidential. To the extent permitted by
                law, you are responsible for all actions submitted and data
                accessed by your PayQue Account, and PayQue holds no liability
                for any costs, difficulties, or disputes arising from
                unauthorized or compromised usage of your PayQue Account, except
                where required by law or where PayQue explicitly accepts
                responsibility.
              </p>
            </div>
            {/* 2.5 */}
            <div className="term-section">
              <h4>2.5 Restrictions/Limitations</h4>
              <p style={{ paddingTop: 10 }}>
                Successful registration provides you access to the Services, but
                does not make any warranties about the extent of your access or
                privileges. PayQue reserves the right to impose limitations on
                the size, frequency, and time frame of payments submitted.
                <br />
                Depending on your usage of the Services, more information may
                be requested to lift such restrictions. You acknowledge that
                your failure to provide satisfactory information upon request
                may result in limitations being placed on your PayQue Account,
                which may include complete restriction from access and usage of
                the Services.
                <br />
                Furthermore, we reserve the right to review or place a hold on
                any payment requested through our Services for any reason,
                including but not limited to credit and fraud risk or compliance
                with applicable laws, such as anti-money laundering regulations,
                combating terrorist financing laws, and OFAC sanctions. In our
                sole discretion, PayQue may place a hold on a payment for as
                long as reasonably necessary to conduct an appropriate inquiry
                regarding the payment, a PayQue Account, a Recipient, or any
                related facts or circumstances ("Payment Review"). Depending on
                the results of this review, PayQue reserves the right to take
                any appropriate action, including declining the payment,
                refunding the payment, or continuing to hold the payment pending
                instructions from a government agency. You relieve PayQue of any
                liability you may incur due to payments that are held, denied,
                or reversed.
                <br />
                When making a transaction, each party must comply with the rule
                of law and provide proof of payment, such as an invoice or
                contract, for PayQue to successfully process the transaction.
                PayQue reserves the right to terminate the transaction at any
                time if it determines that the information provided is
                inaccurate.
              </p>
            </div>
            {/* 2.6 */}
            <div className="term-section">
              <h4>2.6 Termination/Suspension</h4>
              <p style={{ paddingTop: 10 }}>
                If we have reason to believe that you have used our Services
                contrary to the terms of this Agreement, including without
                limitation failing to pay applicable fees or charges, we may
                close or suspend your Account or the Services; hold, return, or
                reclaim funds; update information provided by third parties;
                decline to provide Services to you or related parties in the
                future. If you open additional accounts, PayQue reserves the
                right to immediately close such additional accounts.
                <br />
                We further reserve the right to terminate your PayQue Account
                if: you provide inaccurate, untrue, or incomplete information;
                you fail to comply with the account registration requirements;
                we cannot verify your identity; or you engage in any restricted
                activities or inappropriate use of the Services. PayQue, in its
                sole discretion, also reserves the right to close your PayQue
                Account at any time with or without notice to you for any reason.
                <br />
                Activity contrary to the terms of this Agreement may result in
                us contacting your bank, other Users, law enforcement, or other
                third parties; or taking legal action.
              </p>
            </div>
            {/* 2.7 */}
            <div className="term-section">
              <h4>2.7 Applicable Taxes and Penalties</h4>
              <p style={{ paddingTop: 10 }}>
                You are responsible for all, if any, applicable taxes arising
                from your use of the Services. You are furthermore responsible
                for all fees, fines, penalties, and other liabilities incurred
                by PayQue, yourself, or a third party caused by or arising out
                of your breach of this Agreement and/or your use of the Services.
                You agree to reimburse PayQue or a third party for any and all
                such liabilities.
                <br />
                In the event that you are liable for any amounts owed to PayQue,
                PayQue may initiate a transaction via your default Payment Method
                or other available payment methods to pay any such amounts. If
                you do not have sufficient funds available to fulfill such
                payment, PayQue may engage in collection efforts and/or other
                legal actions to recover such amounts from you.
              </p>
            </div>
            {/* 2.8 */}
            <div className="term-section">
              <h4>2.8 Privacy</h4>
              <p style={{ paddingTop: 10 }}>
                Personal identifiable information ("Personal Data") provided
                through accessing the Website, including all subpages and
                successor pages, and using the Services is governed by our
                Privacy Policy located at payque.com/privacy. You agree to and
                are bound by the terms of our Privacy Policy, which is
                incorporated by reference into this Agreement as if it were set
                forth herein in its entirety.
                <br />
                To help combat terrorism and money laundering activities, PayQue
                may obtain, verify, and sometimes store certain Personal Data.
                You authorize PayQue, directly or through third parties, to
                make any inquiries necessary to comply with applicable laws or
                regulations. This may include asking you for further information,
                requiring you to take steps to confirm ownership of your email
                address or financial instruments, and verifying your information
                against third-party databases or through other sources.
                <br />
                At any time during the term of this Agreement and your use of
                the Services, we may require additional information from you to
                verify beneficial ownership or control of the business, validate
                the information you provided, and verify your identity or the
                identity of your business. If PayQue cannot verify your identity,
                PayQue reserves the right to deny you use of the Services.
                <br />
                For businesses that share data with PayQue, you represent and
                warrant that you have obtained the necessary permissions to do
                so and that your policies regarding Personal Data comply with
                applicable laws or regulations.
              </p>
            </div>
          
          </div>
        </div>

        <div className="button__row">
          <button className="button bordered" onClick={closeModal}>
            Reject
          </button>
          <button className="button green" onClick={setChecked}>
            Agree
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;

