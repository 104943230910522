import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Sidebar from "./Base/Sidebar";
import Profile from "./Profile/Profile";
import Wallet from "./Wallet/Wallet";
import HeaderMob from "./Base/HeaderMob";
import Transaction from "./Transaction/Transaction";
import Inbox from "./Inbox/Inbox";
import Contract from "./Contract/Contract";
import Payment from "./Payment/Payment";
// import MASTER_CARD_ICON from "./assets/masterCard.png";
import { getPayee } from "./service/user";
import { getCards } from "./service/payment";
import Overview from "./Overview/Overview";
import { CARDICON } from "./Base/constants";
import { getProfile } from "./service/user";
import { toast } from "react-toastify";

export default function AppInner({ setIsAuth }) {
  const [menu, setMenu] = useState(false);
  const [payNow, setPayNow] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu]);
  useEffect(() => {
    setMenu(false);
    document.body.classList.remove("active");
  }, [navigate]);

  // card functions
  const [form, setForm] = useState({
    companies: [
      // {
      //   id: "1",
      //   photo: "avatar.png",
      //   avatar: "/images/avatars/avatar-3.png",
      //   contract_file: "",
      //   paying_for: "Feby Sabilhul Hanafi",
      //   phone_number: "6282230114136",
      //   email: "Febysabilhul@gmail.com",
      //   contract_number: "12",
      //   contract_period: {
      //     id: "2",
      //     value: "months",
      //   },
      //   start_date: new Date(),
      //   end_date: new Date(),
      //   category: {
      //     id: "1",
      //     value: "Option A",
      //   },
      //   subCategory: {
      //     id: "2",
      //     value: "Option B",
      //   },
      //   payment_type: {
      //     type: "paper_check",
      //     hint: "(Standard Delivery is FREE * Expedited Delivery Available)",
      //     expect: "Expect in 2-11 Days",
      //   },
      //   paper_check: {
      //     answer: "Write your answer",
      //     province: "Alberta",
      //     address:
      //       "Dsn. Sumbersuko, RT/RW 01/01, Desa. Kesilir, Kec. Siliragung",
      //     city: "Calgary",
      //     country: "Canada",
      //     zip: "T1A 1A1",
      //   },
      //   e_interac: {
      //     cell: "",
      //     email: "",
      //   },
      //   wire: {
      //     companyName: "",
      //     firstName: "",
      //     branchNumber: "",
      //     accountNumber: "",
      //   },
      // },
    ],
    payment: [],
    cards: [
      // {
      //   id: "1",
      //   name: "Karol Buratynski",
      //   date: "05/24",
      //   cardNumber: "5555555555554444",
      //   cvv: "123",
      //   cardType: "MASTERCARD",
      //   cardImage: MASTER_CARD_ICON,
      //   save: true,
      // },
    ],
  });
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
    fetchCards()
    fetchPayees()
  };

  //get All payee
  const fetchPayees = async () => {
    try {
      const response = await getPayee({});
      const { data } = response;
      if (!data) {
        localStorage.removeItem("token");
        window.location.assign("/");
      }
  
      if (data?.success && data?.data?.length) {
        const updatedData = data.data.map((x) => ({
          id: x.id,
          photo: x.photo,
          avatar: x.photo_id,
          contract_file: "",
          paying_for: x.paying_for,
          phone_number: x.phone_number,
          email: x.email,
          contract_number: x.contract_number,
          contract_period: {
            id: x.length_of_contract.toString().split(" ")[0],
            value: x.length_of_contract.toString().split(" ")[1],
          },
          stripe_account_id: x.stripe_account_id,
          start_date: new Date(x.start_date),
          end_date: new Date(x.end_date),
          category: {
            id: "1",
            value: x.category,
          },
          subCategory: {
            id: "2",
            value: x.sub_category,
          },
          payment_type: {
            type: x.payment_method,
          },
  
          paper_check: {
            answer: "",
            province: x.province,
            address: x.address,
            city: x.city,
            country: x.country,
            zip: x.zip,
          },
          e_interac: {
            cell: "",
            email: "",
          },
          wire: {
            companyName: x.last_name_or_company_name,
            firstName: x.company_first_name,
            branchNumber: x.rbc_branch_transit_number,
            accountNumber: x.rbc_branch_account_number,
          },
        }));
  
        setForm((form) => ({ ...form, companies: updatedData }));
      }
    } catch (error) {
      console.error("Error fetching payees:", error);
    }
  };

  //get Cards
  const fetchCards = async () => {
    try {
      const response = await getCards();
      const { data } = response;
      if (!data) {
        localStorage.removeItem("token");
        navigate("/");
      }
      if (data?.success && data?.data?.length) {
        const updatedData = data.data.map((x) => ({
          cardImage: CARDICON[x?.brand.toUpperCase()] || CARDICON.DEFAULT_ICON,
          cardNumber: x?.last4 || "0000",
          cardType: x?.brand || "",
          cvv: x?.cvv || "",
          date:
            x?.exp_month?.toString()?.length > 1
              ? x?.exp_month + "/" + x?.exp_year?.toString()?.slice(-2)
              : "0" + x?.exp_month + "/" + x?.exp_year?.toString()?.slice(-2),
          id: x?.id,
          name: x?.name,
          save: true,
        }));

        setForm((form) => ({ ...form, cards: updatedData }));
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching cards:", error);
    }
  };
  
  useEffect(() => {
    fetchCards();
    fetchPayees();
  }, []);
  

  const [profile, setProfile] = useState({});

  const updateProfile = (data) => {
    setProfile((user) => ({ ...user, ...data }));

  };

  const fetchprofile = async () => {
    const response = await getProfile();
    if (response?.data?.success) {
      updateProfile(response?.data?.data);
    } else {
      toast.error("Failed to fetch profile with", response.status);
    }
  };
  useEffect(() => {
    fetchprofile();
  }, []);

  return (
    <>
      <HeaderMob menu={menu} setMenu={setMenu} />
      <Sidebar menu={menu} setMenu={setMenu} setIsAuth={setIsAuth} />
      <div className="wrapper">
        <Routes>
          <Route index element={<Overview form={form} profile={profile}/>} />
          <Route path="/inbox" element={<Inbox form={profile}/>} />
          <Route
            path="/make-payment"
            element={
              <Payment
                setPayNow={setPayNow}
                payNow={payNow}
                form={form}
                profile={profile}
                updateForm={updateForm}
              />
            }
          />
          <Route path="/profile" element={<Profile form={profile} setForm={setProfile} updateForm={updateProfile} />} />
          <Route
            path="/contract"
            element={
              <Contract
                setPayNow={setPayNow}
                form={form}
                profile={profile}
                updateForm={updateForm}
              />
            }
          />
          <Route path="/history" element={<Transaction form={profile} />} />
          <Route
            path="/wallet"
            element={<Wallet form={form} profile={profile} updateForm={updateForm} />}
          />
        </Routes>
      </div>
    </>
  );
}
