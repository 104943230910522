import React from "react";
import { inboxes } from "../../../data/data";

export default function Side({ inbox, setInbox }) {
  return (
    <div className="inboxSide">
      <div className="inboxSide__inner">
        {inboxes.map((item, index) => {
          return (
            <div
              className={
                "inboxSide__btn " + (inbox.id === item.id ? "active" : "")
              }
              onClick={() => setInbox(item)}
              key={index}
            >
              <h6>{item.date}</h6>
              <p>{item.subject}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
