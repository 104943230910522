import React, { useState, useRef, useEffect } from "react";
import LoadingRing from "./LoadingRing";
import { uploadFile } from "../../../../../service/user";
import { tickIcon } from "../../../../../Base/SVG";
import axios from "axios";
import api from "../../../../../config/api";
import { toast } from "react-toastify";
const fetchingStates = {
  idle: "STATE_IDLE",
  error: "STATE_ERROR",
  fetching: "STATE_FETCHING",
  success: "STATE_SUCCESS",
};

const PhotoId = ({ imageData, updatePos, error }) => {
  const [status, setStatus] = useState(fetchingStates.idle);
  const readFile = ({ file, onStart, onLoad, onLoadend }) => {
    if (file) {
      var reader = new FileReader();
      onStart();
      reader.onload = ({ target }) => onLoad(target.result);
      reader.onloadend = () => onLoadend();
      reader.readAsDataURL(file);
    }
  };
  const [imageFile, setImageFile] = useState(null);
  const previewImage = async (e) => {
    setImageFile(e.target.files[0]);
    e.target.value = null;
    if (imageFile) {
      readFile({
        file: imageFile,
        onStart: () => setStatus(fetchingStates.fetching),
        onLoad: (result) => onLoad(result, imageFile),
        onLoadend: () => setStatus(fetchingStates.success),
      });

      // try {
      //   let formData = new FormData();
      //   formData.append("file", imageFile);

      //   let { data } = await axios.post(api.user.uploadFile, formData, {
      //     headers: {
      //       "content-type": "multipart/form-data",
      //       Authorization: `Bearer ${localStorage.getItem("token")}`,
      //     },
      //   });
      //   if (data?.success) {
      //   }
      // } catch (error) {
      //   console.log("error in uploading file", error);
      // }
    }
  };
  const onLoad = (resultImage, imageFile) => {};
  const [kycStatus, setKycStatus] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const fetchKycData = async () => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: api.user.userKyc,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.request(config);
      console.log(response.data);
      if (response.data.success) {
        setKycStatus(response.data.data.status);
        setUploadedImage(response.data.data.photo_id);
      }
    } catch (error) {
      console.log("error in fetching kyc data", error);
    }
  };

  const uploadPhotoId = async () => {
    try {
      if (!imageFile) {
        toast.error("Please upload a photo");
        return;
      }
      let formData = new FormData();
      formData.append("file", imageFile);

      let { data } = await axios.post(api.user.userKyc, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (data?.success) {
        toast.success("Photo uploaded successfully");
      }
    } catch (error) {
      console.log("error in uploading file", error);
    }
  };

  useEffect(() => {
    fetchKycData();
  }, []);

  return (
    <div className="fileUp__outer">
      {!kycStatus && (
        <>
          <h6 className="big" style={{ marginBottom: "20px" }}>
            Upload Photo ID:
          </h6>

          <label htmlFor="">Photo ID (.pdf .jpg)</label>
          <div className="fileUp">
            {imageData && (
              <div className="fileUp__text">
                {imageData.name} {tickIcon}
              </div>
            )}
            <div className="fileUp__btn">
              <input
                type="file"
                onChange={previewImage}
                accept="image/jpeg,image/png"
              />
              {imageFile && (
                <img
                  src={URL.createObjectURL(imageFile)}
                  alt="preview"
                  style={{ width: "330px", height: "200px" }}
                />
              )}

              {status === fetchingStates.fetching ? (
                <LoadingRing size={"12px"} color={"#7de19a"} />
              ) : (
                <span>Select File</span>
              )}
            </div>
          </div>
          {error && imageData === "" && (
            <span className="errorM">Photo upload required</span>
          )}
          <button
            onClick={uploadPhotoId}
            className="button green"
            style={{ margin: "20px 0px" }}
          >
            {" "}
            Submit
          </button>
        </>
      )}

      <div>
        {/* <h6>Uploaded Photo ID:</h6>
        {uploadedImage ? (
          <img src={uploadedImage} alt="uploaded" />
        ) : (
          <p style={{margin:"10px 0px"}}>
            <strong>
            Not Uploaded
            </strong>
            </p>
        )} */}
        <p>
          <strong>Status :</strong> {kycStatus ? "Verified" : "Not Verified"}
        </p>
      </div>
    </div>
  );
};

export default PhotoId;
