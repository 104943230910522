import React, { useEffect, useState } from "react";
import CustomSelect from "./CustomSelect";
import Autocomplete from "react-google-autocomplete";

const provinceList = [
  {
    id: "1",
    value: "Alberta",
  },
  {
    id: "2",
    value: "British Columbia",
  },
  {
    id: "3",
    value: "Manitoba",
  },
  {
    id: "4",
    value: "New Brunswick",
  },
  {
    id: "5",
    value: "Newfoundland and Labrador",
  },
  {
    id: "6",
    value: "Northwest Territories",
  },
  {
    id: "7",
    value: "Nova Scotia",
  },
  {
    id: "8",
    value: "Nunavut",
  },
  {
    id: "9",
    value: "Ontario",
  },
  {
    id: "10",
    value: "Prince Edward Island",
  },
  {
    id: "11",
    value: "Quebec",
  },
  {
    id: "12",
    value: "Saskatchewan",
  },
  {
    id: "13",
    value: "Yukon Territory",
  },
];
export default function Type1({ pos, updatePos, error }) {
  const [typeInfo, setTypeInfo] = useState(pos.paper_check);
  const updateTypeInfo = (data) => {
    setTypeInfo((typeInfo) => ({ ...typeInfo, ...data }));
  };
  const onChangeInput = (input) => (e) => {
    setTypeInfo((typeInfo) => ({ ...typeInfo, [input]: e.target.value }));
  };
  const changeProvince = (item) => {
    updateTypeInfo({ province: item.value });
  };
  const provinceIndex = React.useMemo(() => {
    if (typeInfo.province !== "") {
      return provinceList.findIndex((item) => item.value === typeInfo.province);
    }
    return null;
  }, [typeInfo]);
  useEffect(() => {
    updatePos({ paper_check: typeInfo });
  }, [typeInfo]);
  useEffect(() => {
    setTypeInfo(pos.paper_check);
  }, [pos.paper_check]);

  return (
    <>
      <div className="input__outer">
        <label htmlFor="">Address</label>
        <div
          className={
            "input " + (error && pos.paper_check.address === "" ? "error" : "")
          }
        >
          <Autocomplete
            apiKey="AIzaSyCmLbwSJ3Ef4cyy_r2X1i9jQz9rca60P2I"
            options={{
              types: ["address"],
            }}
            value={typeInfo.address}
            onChange={onChangeInput("address")}
            onPlaceSelected={(place) => {
              place.address_components.forEach((item) => {
                item.types.forEach((typeItem) => {
                  if (typeItem === "locality" || typeItem === "postal_town") {
                    updateTypeInfo({
                      city: item.long_name,
                    });
                  } else if (typeItem === "country") {
                    updateTypeInfo({
                      country: item.long_name,
                    });
                  } else if (typeItem === "administrative_area_level_1") {
                    updateTypeInfo({
                      province: item.long_name,
                    });
                  } else if (typeItem === "postal_code") {
                    updateTypeInfo({
                      zip: item.long_name,
                    });
                  }
                });
              });
              updateTypeInfo({
                address: place?.formatted_address,
              });
            }}
          />
          {error && pos.paper_check.address === "" && (
            <span className="errorM">This field required</span>
          )}
        </div>
      </div>

      <div className="formm__double">
        <div className="input__outer">
          <label htmlFor="">Province</label>
          <CustomSelect
            onChange={changeProvince}
            list={provinceList}
            selected={provinceList[provinceIndex]}
          />
        </div>
        <div className="input__outer">
          <label htmlFor="">City</label>
          <div
            className={
              "input " + (error && pos.paper_check.city === "" ? "error" : "")
            }
          >
            <input
              value={typeInfo.city}
              onChange={onChangeInput("city")}
              type="text"
              placeholder="City"
            />
            {error && pos.paper_check.city === "" && (
              <span className="errorM">This field required</span>
            )}
          </div>
        </div>
      </div>
      <div className="formm__double">
        <div className="input__outer">
          <label htmlFor="">Country </label>
          <div
            className={
              "input " +
              (error && pos.paper_check.country === "" ? "error" : "")
            }
          >
            <input
              value={typeInfo.country}
              onChange={onChangeInput("country")}
              type="text"
              placeholder="Country"
            />
            {error && pos.paper_check.country === "" && (
              <span className="errorM">This field required</span>
            )}
          </div>
        </div>
        <div className="input__outer">
          <label htmlFor="">Postal Code </label>
          <div
            className={
              "input " + (error && pos.paper_check.zip === "" ? "error" : "")
            }
          >
            <input
              value={typeInfo.zip}
              onChange={onChangeInput("zip")}
              type="text"
              placeholder="XXX-XXX"
            />
            {error && pos.paper_check.zip === "" ? (
              <span className="errorM">This field required</span>
            ) : error &&
              pos.paper_check.zip !== "" &&
              /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/.test(
                pos.paper_check.zip
              ) === false ? (
              <span className="errorM">Invalid postal code</span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
