import React, { useEffect, useState } from "react";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import useMediaQuery from "../../Hooks/useMediaQuery";

export default function SignUp({ setIsAuth }) {
  const [step, setStep] = useState("step1");
  const width = useMediaQuery();
  const [mobImage, setMobImage] = useState(false);

  const [userData, setuserData] = useState({})

  useEffect(() => {
    if (width <= 1024) {
      setMobImage(true);
    } else setMobImage(false);
  }, [width]);
  return (
    <div className="auth">
      <div className="auth__inner">
        <div className="auth__content">
          <div className="auth__content-inner">
            {step === "step1" && <Step1 setStep={setStep} userData={userData} setuserData={setuserData} />}
            {step === "step2" && <Step2 setStep={setStep} userData={userData} setuserData={setuserData} preFilledEmail={userData.email} setIsAuth={setIsAuth}/>}
            {/* {step === "thanks" && <Thanks setIsAuth={setIsAuth} />} */}
          </div>
        </div>
        <div className="auth__image">
          {mobImage ? (
            <img
              src={process.env.PUBLIC_URL + "/images/signUpMob.png"}
              alt="signUpMob"
            />
          ) : (
            <img
              src={process.env.PUBLIC_URL + "/images/signUp.png"}
              alt="signUp"
            />
          )}
        </div>
      </div>
    </div>
  );
}