import axios from 'axios';
import api from '../config/api';



export const getProfile = async () => {
    let request = await axios({
        method: "get",
        url: `${api.user.getprofile}`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    }).catch(err => new Error(err));
    return request;
};

export const updateProfile = async (data,id) => {
    let request = await axios({
        method: "patch",
        url: `${api.user.updateprofile}?id=${id}`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data,
    }).catch(err => new Error(err));
    return request;
};

export const createPayee = async (data) => {
    try {
        const response = await axios({
            method: "post",
            url: `${api.user.createPayee}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            data,
        });

        return response;
    } catch (error) {
        console.error('Error in createPayee:', error);
        throw error;
    }
};


export const getPayee = async ({ pageNumber = 1, pageSize = 10 }) => {
    let request = await axios({
        method: "get",
        url: `${api.user.getPayee}`.replace('{{page_number}}', pageNumber).replace('{{page_size}}', pageSize),
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    }).catch(err => new Error(err));
    return request;
};

export const deletePayee = async ({ payee_id }) => {
    let request = await axios({
        method: "delete",
        url: `${api.user.deletePayee}`.replace('{{payee_id}}', payee_id),
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    }).catch(err => new Error(err));
    return request;
};

export const uploadFile = async (formData) => {
    let request = await axios({
        method: "post",
        url: `${api.user.uploadFile}`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            "content-type": "multipart/form-data",
        },
        formData,
    }).catch(err => new Error(err));
    return request;
};

export const lineGraph = async () => {
    let request = await axios({
        method: "get",
        url: `${api.user.lineGraph}`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    }).catch(err => new Error(err));
    return request;
};
export const pieGraph = async () => {
    let request = await axios({
        method: "get",
        url: `${api.user.pieGraph}`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    }).catch(err => new Error(err));
    return request;
};
