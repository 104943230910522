import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  chartIcon,
  contractIcon,
  historyIcon,
  inboxIcon,
  logOut,
  profileIcon,
  walletIcon,
} from "./SVG";

export default function Sidebar({ menu, setMenu, setIsAuth }) {
  const onClose = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  return (
    <div className={"sidebar " + (menu ? "active" : "")} onClick={onClose}>
      <div className="sidebar__inner">
        <div className="sidebar__top">
          <Link to="" className="sidebar__inner-logo">
            <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" />
          </Link>
          <h6>General</h6>
          <NavLink to="/inbox" className="sidebar__inner-link">
            {inboxIcon}
            <span>Inbox</span>
          </NavLink>
          <NavLink to="/make-payment" className="sidebar__inner-link">
            {chartIcon}
            <span>Make a payment</span>
          </NavLink>
          <NavLink to="/contract" className="sidebar__inner-link">
            {contractIcon}
            <span>Contract</span>
          </NavLink>
          <NavLink to="/wallet" className="sidebar__inner-link">
            {walletIcon}
            <span>Wallet</span>
          </NavLink>
          <NavLink to="/history" className="sidebar__inner-link">
            {historyIcon}
            <span>History</span>
          </NavLink>
          <NavLink to="/profile" className="sidebar__inner-link">
            {profileIcon}
            <span>Profile</span>
          </NavLink>
        </div>
        <Link
          to="/"
          className="sidebar__inner-link"
          onClick={() => { setIsAuth(false); localStorage.removeItem('token') }}
        >
          {logOut} <span>Log Out</span>
        </Link>
      </div>
    </div>
  );
}
