import React, { useState, useRef } from "react";
import { readFile } from "./fileReader";
import LoadingRing from "./LoadingRing";
import { cameraIcon, userIcon } from "../../../Base/SVG";
import profilePic from "../../../Base/avatar2.png";

const fetchingStates = {
  idle: "STATE_IDLE",
  error: "STATE_ERROR",
  fetching: "STATE_FETCHING",
  success: "STATE_SUCCESS",
};

const ChooseImage = ({ addClass = "", active, imageData, updateForm }) => {
  const fileRef = useRef();
  const [status, setStatus] = useState(fetchingStates.idle);

  const previewImage = () => {
    if (fileRef.current.files && fileRef.current.files[0]) {
      readFile({
        file: fileRef.current.files[0],
        onStart: () => setStatus(fetchingStates.fetching),
        onLoad: (result) => onLoad(result),
        onLoadend: () =>
          setTimeout(() => setStatus(fetchingStates.success), 2000),
      });
    }
  };
  const onLoad = (resultImage) => {
    updateForm({
      avatar: resultImage,
    });
  };

  // const deleteFunc = () => {
  //   updateForm({ avatar: "" });
  //   setStatus(fetchingStates.idle);
  // };

  return (
    <div className={`upload ${active ? "active" : ""}`}>
      <div className="upload__avatar">
        {status === fetchingStates.fetching ? (
          <LoadingRing size={20} color={"#0a4c86"} />
        ) : imageData ? (
          <img src={imageData} alt="profile" />
        ) : (
          // <span className="icon">{userIcon}</span>
          <img src={profilePic} alt="profile" />
        )}
      </div>
      <div className="upload__btn">
        <input
          type="file"
          onChange={previewImage}
          ref={fileRef}
          accept="image/*"
        />
        {cameraIcon}
      </div>
    </div>
  );
};

export default ChooseImage;
