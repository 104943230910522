import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { cardIcon } from "../../../Base/SVG";

export default function Cards({ form }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="overviewSide__cards">
      <h4>My Card</h4>
      <div className="overviewSide__cards-slider">
        <Slider {...settings}>
          {form.cards.map((item, index) => {
            return (
              <div className="card" key={index}>
                <div className="card__bg">
                  <img
                    src={process.env.PUBLIC_URL + "/images/frame.png"}
                    alt="frame"
                  />
                </div>
                <div className="card__inner">
                  <div className="card__head">
                    <div className="card__head-icon">{cardIcon}</div>
                    <div className="card__head-type">
                      <img src={item.cardImage} alt="" />
                      <span>{item.cardType}</span>
                    </div>
                  </div>
                  <div className="card__body">
                    <h6>{item.name}</h6>
                    <p className="sm">{item.cardNumber}</p>
                    <p className="xsm">{item.date}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="button__row">
        <Link to="/wallet" className="button green">
          Manage Cards
        </Link>
        <Link to="" className="button bordered">
          Transfer
        </Link>
      </div>
    </div>
  );
}
