import React from "react";

export default function Cheque({ pos, exportRef }) {
  return (
    <>
      <div className="div" id="printablediv">
        <div className="cheque" ref={exportRef} id="">
          <div className="cheque__text cheque__pay">{pos.amount_words}</div>
          <div className="cheque__text cheque__for">{pos.pay}</div>
          <div className="cheque__text cheque__total">{pos?.amount}</div>
          <div className="cheque__text cheque__memo">{pos.memo_text}</div>
          <img
            src={process.env.PUBLIC_URL + "/images/cheque.jpg"}
            alt="cheque"
          />
        </div>
      </div>
    </>
  );
}
