import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomSelect from "./CustomSelect";
import { sendOpt, verifyOtp } from "../../../service/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const numbers = [
  {
    id: "1",
    value: "+1 CA",
  },
  {
    id: "2",
    value: "+993 TM",
  },
  {
    id: "3",
    value: "+772 RU",
  },
  {
    id: "4",
    value: "+1 CA",
  },
  {
    id: "5",
    value: "+91 IND",
  },
];

const initialCountdown = 10; // 2 minutes in seconds

export default function Step1({ setStep, setuserData }) {
  const [showPass, setShowPass] = useState(false);
  const [act, setAct] = useState("act1");
  const [checkedType, setcheckedType] = useState(1);
  const [mail, setmail] = useState("");
  const [otp, setotp] = useState("");
  const [allVerified, setallVerified] = useState(false);
  const [countdown, setCountdown] = useState(initialCountdown);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [countdown]);

  const sendMail = async () => {
    if (mail) {
      if (mail.length > 7) {
        let payload = {
          id: "user",
          data: { email: mail },
        };

        try {
          let { data } = await sendOpt(payload);

          if (data.success) {
            toast.success("Email sent successfully!");
            setAct("act2");
            setCountdown(initialCountdown);
          }
        } catch (error) {
          console.error("Error sending email:", error);
          toast.error("Account already exists");
        }
      }
    } else {
      toast.error("Email required!");
    }
  };

  const verifyOtpforEmail = async () => {
    if (otp) {
      let payload = {
        id: "user",
        data: { email: mail, otp: otp },
      };

      let { data } = await verifyOtp(payload);

      if (data.success) {
        setallVerified(true);
        toast.success("Email Verified!");
        setuserData({ email: mail });
        setStep("step2");
      } else {
        toast.error("Invalid OTP");
      }
    }
    if (!otp) {
      toast.error("OTP required");
    }
  };

  const handleBack = () => {
    setAct("act1");
  };

  const resendOtp = async() => {
    if (mail) {
      if (mail.length > 7) {
        let payload = {
          id: "user",
          data: { email: mail },
        };

        try {
          let { data } = await sendOpt(payload);

          if (data.success) {
            toast.success("Email re-sent!");
            setCountdown(initialCountdown);
          }
        } catch (error) {
          console.error("Error sending email:", error);
          toast.error("Account already exists");
        }
      }
    } else {
      toast.error("Email required!");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="auth__content-head">
        <h3>Get started</h3>
        <Link to="/">
          <div className="auth__content-logo">
            <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" />
          </div>
        </Link>
      </div>
      <div className="step">
        <div className="step__body">
          <div className={"step__body-act " + (act === "act2" ? "hidden" : "")}>
            <h5>Verification</h5>
            <div className="phone">
              <p>Enter an Email Address</p>
              <div className="input__outer">
                <div className="input">
                  <input
                    type="text"
                    placeholder="Your Email Address"
                    value={mail}
                    onChange={(e) => setmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <button type="button" className="button green" onClick={sendMail}>
              Send Mail
            </button>
          </div>
          {act === "act2" && (
            <>
            <div className="step__body-act">
              <h5>Verification</h5>
              <div className="input__outer">
                <div className="input">
                  <input
                    type="text"
                    placeholder="123456"
                    maxLength={5}
                    minLength={5}
                    value={otp}
                    onChange={(e) => setotp(e.target.value)}
                  />
                </div>
              </div>
              <div className="button__row">
                <div className="button__row">
                <button
                  type="button"
                  className="button bordered"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="button green"
                  onClick={verifyOtpforEmail}
                >
                  Verify
                </button>
                </div>
                {countdown > 0 && (
                  <p>
                    Time Remaining: {Math.floor(countdown / 60)}:
                    {(countdown % 60).toString().padStart(2, "0")}
                  </p>
                )}
                {countdown === 0 && (
                    <button type="button" className="href link" onClick={resendOtp}>
                      RESEND OTP
                    </button>
                )}
              </div>
              <div className="button__row">
              
              </div>
            </div>
           
            </>
          )}
        </div>
        <div className="step__foot">
          <div className="step__foot-text">Steps 1/2</div>
          <div className="step__foot-dots">
            <span></span>
            <span className="active"></span>
          </div>
          {act === "act1" ? (
            <button
              type="button"
              className="button submit"
              onClick={() => setAct("act2")}
              disabled={!allVerified}
            >
              Next
            </button>
          ) : act === "act2" ? (
            <button
              type="button"
              className="button submit"
              onClick={verifyOtpforEmail}
            >
              Finish
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
