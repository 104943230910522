import React from "react";
import { bellIcon } from "./SVG";
import { Link } from "react-router-dom";

export default function Header({title,text,form}) {
  return (
    <div className="header">
      <div className="header__inner">
        <div className="header__info">
          <h4>{title}</h4>
          <p>{text}</p>
        </div>
        <div className="header__btns">
          <Link to="" className="notBtn">
            {bellIcon}
            <span></span>
          </Link>
          <Link to="/profile" className="avatarBtn" style={{borderRadius:'50px'}}>

          {
           ( form.avatar && form.avatar.length > 0) ?(
              <img
              src={form.avatar}
              alt="avatar"
              style={{borderRadius:'50px'}}
            />
            ):(
              <img
              src={process.env.PUBLIC_URL + "/images/avatar2.png"}
              alt="avatar"
              style={{borderRadius:'50px'}}/>
            )
          }
           
          </Link>
        </div>
      </div>
    </div>
  );
}
